import { CSSProperties } from "react";

interface TitleProps {
  text: string;
  smallTopMargin?: boolean;
  style?: CSSProperties;
}

export function Title(props: TitleProps) {
  return (
    <div
      style={{
        textAlign: "center",
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "16px",
        marginBottom: "10px",
        marginTop: props.smallTopMargin ? undefined : "30px",
        ...props.style,
      }}
    >
      {props.text}
    </div>
  );
}
