import { ArrowLeftOutlined } from "@ant-design/icons";
import { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as localForage from "localforage";
import { LF_RESTORE_SCROLL } from "../../lib/definitions";

interface HeaderProps {
  title: string;
  level: 1 | 2 | 3;
  extra?: React.ReactNode;
  smallTopMargin?: boolean;
  style?: CSSProperties;
  onBackUrl?: string | null;
  noBack?: boolean;
  className?: string;
}

export function Header(props: HeaderProps) {
  const [backUrl, setBackUrl] = useState("");
  const history = useHistory();

  useEffect(() => {
    setBackUrl(new URLSearchParams(window.location.search).get("back-url") || "");
  }, []);

  const marginTop = props.smallTopMargin ? 16 : props.level === 1 ? 30 : props.level === 2 ? 60 : 24;
  const marginBottom = props.level === 1 ? 20 : props.level === 2 ? 16 : 12;

  return (
    <div
      style={{
        borderTop: "1px solid #e4e7eb",
        fontSize: `${12 + 3 * (4 - props.level)}px`,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        padding: `${2 + 2 * (4 - props.level)}px 16px`,
        color: "#636363",
        background: "white",
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        justifyContent: "space-between",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        ...props.style,
      }}
      className={props.className}
    >
      <div>
        {(backUrl || props.onBackUrl) && !props.noBack && (
          <ArrowLeftOutlined
            style={{ marginRight: "16px" }}
            onClick={async () => {
              await localForage.setItem(LF_RESTORE_SCROLL, true);
              history.push((decodeURIComponent(backUrl) || props.onBackUrl) as string);
            }}
          />
        )}
        {props.title}
      </div>
      {props.extra}
    </div>
  );
}
