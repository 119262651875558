import { CSSProperties } from "react";

interface SectionContainerProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export function SectionContainer(props: SectionContainerProps) {
  return <div style={{ margin: "0 36px", ...props.style }}>{props.children}</div>;
}
