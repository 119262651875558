interface DetailsGridProps {
  children: React.ReactNode;
}

export function DetailsGrid(props: DetailsGridProps) {
  return (
    <div style={{ display: "grid", columnGap: "8px", rowGap: "16px", fontSize: "20px", gridTemplateColumns: "auto auto" }}>
      {props.children}
    </div>
  );
}
