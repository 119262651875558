import { useCallback, useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { DataContext } from "../lib/contexts";
import { APIResources, DOMAIN, LF_CHECK_NAME, LF_SCROLL_POS_TRACKS } from "../lib/definitions";
import { checkVersionRefresh, doFetch } from "../lib/functions";
import { NavItem, Session, Stint, StintExtended, User, UserInfo } from "../lib/models";
import { UpsertSessionScreen } from "../screens/upsertSessionScreen";
import { UpsertStintScreen } from "../screens/upsertStintScreen";
import { SessionScreen } from "../screens/sessionScreen";
import { StintsScreen } from "../screens/stintsScreen";
import { StintScreen } from "../screens/stintScreen";
import { TrackListScreen } from "../screens/trackListScreen";
import { TrackScreen } from "../screens/trackScreen";
import { UserScreen } from "../screens/userScreen";
import { NavigationBar } from "./navigationBar";
import * as localForage from "localforage";
import { LeaderboardsScreen } from "../screens/leaderboardsScreen";
import { EventOverviewScreen } from "../screens/eventOverview";
import { TrackSessionsScreen } from "../screens/trackSessionsScreen";
import { TrackStintsScreen } from "../screens/trackStintsScreen";
import { TrackOnboardsScreen } from "../screens/trackOnboardsScreen";
import { FirstTimeSetupScreen } from "../screens/firstTimeSetupScreen";

interface MainProps {
  userInfo: UserInfo;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [currentNavItem, setCurrentNavItem] = useState<NavItem>("Track");
  const [allSessions, setAllSessions] = useState<Session[]>([]);
  const [stints, setStints] = useState<StintExtended[]>([]);
  const [myStints, setMyStints] = useState<StintExtended[]>([]);
  const [uncoupledSessions, setUncoupledSessions] = useState<Session[]>([]);
  const [isInputting, setIsInputting] = useState(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isWorking, setIsWorking] = useState(false);
  const [firstTimeInfo, setFirstTimeInfo] = useState<{ [k in string]: number }>();
  const [hasSetMyStintsOnce, setHasSetMyStintsOnce] = useState(false);

  const toggleRef = useRef(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => setIsInputting(false), [location]);

  const refresh = useCallback(
    async (onFinally?: () => void) => {
      setIsWorking(true);
      doFetch(
        "GET",
        `https://api.${DOMAIN}/users`,
        setAllUsers,
        () => alert("Fetching all users: An error occurred"),
        undefined,
        undefined,
        true
      );

      let errorMessage = "";
      let preSessions: Session[] = [];
      let preStints: Stint[] = [];

      const sessionsPromise = doFetch(
        "GET",
        APIResources.Sessions,
        (response) => (preSessions = response),
        (err) => (errorMessage = `An error occured: Fetching sessions: ${err}`)
      );
      const stintsPromise = doFetch(
        "GET",
        APIResources.Stints,
        (response) => (preStints = response),
        (err) => (errorMessage = `An error occured: Fetching stints: ${err}`)
      );

      await sessionsPromise;
      await stintsPromise;

      const sessionIdToStints = new Map<string, Stint[]>();

      preStints.forEach((s) => sessionIdToStints.set(s.sessionId, (sessionIdToStints.get(s.sessionId) || []).concat(s)));

      preSessions = preSessions.map((s) => ({ ...s, stints: sessionIdToStints.get(s._id) || [] }));

      setAllSessions(preSessions);

      const stintsExtended: StintExtended[] = preStints.map((stint) => {
        const session = preSessions.find((session) => session._id === stint.sessionId) as Session;
        return {
          ...stint,
          drivers: (sessionIdToStints.get(session._id) || []).map((s) => s.driver),
          track: session.track,
          session: session,
        };
      });
      const preMyStints = stintsExtended.filter((s) => s.driver === props.userInfo.email);
      const myStintsSessionsIds = preMyStints.map((s) => s.sessionId);

      setUncoupledSessions(
        (props.userInfo.email === "thisbecasper@gmail.com"
          ? preSessions
          : preSessions.filter((session) => !myStintsSessionsIds.includes(session._id))
        ).sort((a, b) => b.timestamp - a.timestamp)
      );
      setStints(stintsExtended);
      setMyStints(preMyStints);
      if (!hasSetMyStintsOnce) {
        setHasSetMyStintsOnce(true);
      }

      if (errorMessage) {
        alert(errorMessage);
      }

      setIsWorking(false);

      if (onFinally) {
        onFinally();
      }
    },
    [props.userInfo, hasSetMyStintsOnce]
  );

  useEffect(() => {
    refresh();
    checkVersionRefresh();

    function refreshOnVisibilityChange() {
      if (toggleRef.current) {
        refresh();
        checkVersionRefresh();
      }
      toggleRef.current = !toggleRef.current;
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", refreshOnVisibilityChange);
    };
  }, [refresh]);

  useEffect(() => {
    if (!props.userInfo) {
      history.push("/login");
    }
  }, [props.userInfo, history]);

  useEffect(() => {
    if (!location.pathname.includes("/tracks")) {
      localForage.setItem(LF_SCROLL_POS_TRACKS, 0);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const v = await localForage.getItem(LF_CHECK_NAME);
      if (hasSetMyStintsOnce && allUsers.length > 0 && stints.length > 0 && myStints.length === 0 && !v) {
        await localForage.setItem(LF_CHECK_NAME, true);

        const notExist: { [k in string]: number } = {};

        stints.forEach((s) => {
          if (!allUsers.find((u) => u.email === s.driver)) {
            if (!notExist[s.driver]) {
              notExist[s.driver] = 1;
            } else {
              notExist[s.driver]++;
            }
          }
        });

        setFirstTimeInfo(notExist);
      }
    })();
  }, [allUsers, stints, myStints, hasSetMyStintsOnce]);

  function getDriverName(email: string | undefined) {
    const driver = allUsers.find((u) => u.email === email);

    if (!driver) {
      return email || "No driver name";
    }

    return driver.fullName;
  }

  return (
    <DataContext.Provider
      value={{
        refresh: refresh,
        userInfo: props.userInfo || { email: "", username: "" },
        sessions: allSessions,
        allStints: stints,
        myStints: myStints,
        uncoupledSessions: uncoupledSessions,
        allUsers: allUsers,
        isWorking: isWorking,
        setIsInputting: setIsInputting,
        getDriverName: getDriverName,
        setCurrentNavItem: setCurrentNavItem,
      }}
    >
      {firstTimeInfo && (
        <FirstTimeSetupScreen
          info={firstTimeInfo}
          onClose={(shouldRefresh) => {
            setFirstTimeInfo(undefined);
            if (shouldRefresh) {
              refresh();
            }
          }}
        />
      )}
      <Switch>
        <Route exact path="/tracks" component={TrackListScreen} />
        <Route exact path="/tracks/:trackId/:layoutId?" component={TrackScreen} />
        <Route exact path="/tracks/:trackId/:layoutId/leaderboards" component={LeaderboardsScreen} />
        <Route exact path="/tracks/:trackId/:layoutId/sessions" component={TrackSessionsScreen} />
        <Route exact path="/tracks/:trackId/:layoutId/stints" component={TrackStintsScreen} />
        <Route exact path="/tracks/:trackId/:layoutId/onboards" component={TrackOnboardsScreen} />
        <Route exact path="/tracks/:trackId/:layoutId/new-session/:sessionId?" component={UpsertSessionScreen} />
        <Route exact path="/sessions/:id" component={SessionScreen} />
        <Route exact path="/stints" component={StintsScreen} />
        <Route exact path="/stints/new-stint/:stintId?" component={UpsertStintScreen} />
        <Route exact path="/stints/:id" component={StintScreen} />
        <Route exact path="/event-overview/:sessionId" component={EventOverviewScreen} />
        <Route exact path="/user" render={() => <UserScreen onLogout={props.onLogout} />} />
        <Route render={() => <Redirect to="/tracks" />} />
      </Switch>
      {!isInputting && !firstTimeInfo && <NavigationBar currentNavItem={currentNavItem} />}
    </DataContext.Provider>
  );
}
