import { Progress } from "antd";

interface StatBarProps {
  value: number;
  ratio: number;
}

export function StatBar(props: StatBarProps) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Progress percent={props.ratio * 100} showInfo={false} width={200} strokeColor="#1890ff" />
      <div style={{ width: "40px", marginLeft: "8px", textAlign: "end", fontStyle: "italic" }}>{props.value}</div>
    </div>
  );
}
