import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { DataContext } from "../lib/contexts";
import { backUrl, calculateDelta, msToLaptime, timestampToYear } from "../lib/functions";
import { InfoType, LeaderboardEntry } from "../lib/models";
import { ListItemCell } from "./general/listItemCell";
import { RadioGroup } from "./general/radioGroup";
import * as localForage from "localforage";
import { LF_INFO_TYPE } from "../lib/definitions";
import { Medal } from "./medal";

interface StandardLeaderboardProps {
  leaderboardEntries: LeaderboardEntry[];
  onlyYear?: boolean;
  withSplitsOption?: boolean;
  onlyHundreths?: boolean;
}

export function StandardLeaderboard(props: StandardLeaderboardProps) {
  const [infoType, setInfoType] = useState<InfoType | "Splits">("Weight");

  const { userInfo, getDriverName } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();

  const placement = useMemo(
    () => props.leaderboardEntries.findIndex((e) => e.driver === userInfo.email),
    [props.leaderboardEntries, userInfo.email]
  );

  useEffect(() => {
    if (placement === -1) {
      setInfoType("Weight");
    } else {
      localForage.getItem(LF_INFO_TYPE, (_err, v) => setInfoType((v as InfoType) || "Weight"));
    }
  }, [placement]);

  function onCellClick(entry: LeaderboardEntry) {
    history.push(`/sessions/${entry.sessionId}?${backUrl(location)}`);
  }

  const options = useMemo(() => {
    const opts = [
      placement !== -1 ? { label: "You", value: "You" } : undefined,
      { label: "Leader", value: "Leader" },
      { label: "Gap", value: "Gap" },
      { label: "Weight", value: "Weight" },
    ];

    if (props.leaderboardEntries.length === 2) {
      opts.pop();
    }

    if (props.withSplitsOption) {
      opts.push({ label: "Splits", value: "Splits" });
    }

    return opts;
  }, [props.withSplitsOption, props.leaderboardEntries, placement]);

  return (
    <div>
      {props.leaderboardEntries.length === 0 ? (
        <div className="placeholder">No times yet¨</div>
      ) : (
        <div>
          {props.leaderboardEntries.length > 1 && (
            <RadioGroup
              options={options}
              onClick={(v) => {
                setInfoType(v);
                localForage.setItem(LF_INFO_TYPE, v);
              }}
              currentValue={infoType}
              style={{ margin: "0 24px 12px 24px" }}
            />
          )}
          <div
            style={{
              display: "grid",
              rowGap: "4px",
              fontSize: "14px",
              gridTemplateColumns:
                infoType === "Splits"
                  ? "auto min-content min-content min-content min-content"
                  : "min-content min-content auto min-content min-content",
              gridAutoRows: "min-content",
            }}
          >
            {props.leaderboardEntries.map((entry, i) => (
              <React.Fragment key={entry.driver}>
                {infoType === "Splits" ? (
                  <>
                    <ListItemCell
                      space={[8, 0]}
                      style={{ fontWeight: entry.driver === userInfo.email ? "bold" : undefined }}
                      onClick={() => onCellClick(entry)}
                    >
                      {getDriverName(entry.driver)}
                    </ListItemCell>
                    <ListItemCell space={4} className="laptime__split" align="end" onClick={() => onCellClick(entry)}>
                      {msToLaptime([entry.splits[0]], props.onlyHundreths)}
                    </ListItemCell>
                    <ListItemCell space={4} className="laptime__split" align="end" onClick={() => onCellClick(entry)}>
                      {msToLaptime([entry.splits[1]], props.onlyHundreths)}
                    </ListItemCell>
                    <ListItemCell space={4} className="laptime__split" align="end" onClick={() => onCellClick(entry)}>
                      {msToLaptime([entry.splits[2]], props.onlyHundreths)}
                    </ListItemCell>
                    <ListItemCell space={[4, 16]} align="end" onClick={() => onCellClick(entry)}>
                      {msToLaptime([entry.time], props.onlyHundreths)}
                    </ListItemCell>
                  </>
                ) : (
                  <>
                    <ListItemCell
                      space={[8, 0]}
                      align="center"
                      onClick={() => onCellClick(entry)}
                      style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                    >
                      {i < 3 ? <Medal type={i === 0 ? "Gold" : i === 1 ? "Silver" : "Bronze"} /> : i + 1 + "."}
                    </ListItemCell>
                    <ListItemCell
                      space={[8, 10]}
                      onClick={() => onCellClick(entry)}
                      style={{ fontStyle: "italic", fontSize: "12px", color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                      innerStyle={{ paddingRight: "1px" }}
                    >
                      {timestampToYear(entry.timestamp)}
                    </ListItemCell>
                    <ListItemCell
                      style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                      onClick={() => onCellClick(entry)}
                    >
                      {getDriverName(entry.driver)}
                    </ListItemCell>
                    <ListItemCell
                      space={4}
                      align="end"
                      onClick={() => onCellClick(entry)}
                      style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                    >
                      {msToLaptime([entry.time], props.onlyHundreths)}
                    </ListItemCell>
                    <ListItemCell
                      space={[4, 12]}
                      onClick={() => onCellClick(entry)}
                      align={infoType === "Weight" && !entry.weight ? "center" : "end"}
                      style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                    >
                      {infoType === "Weight" || props.leaderboardEntries.length === 1
                        ? entry.weight
                          ? entry.weight + "kg"
                          : "---"
                        : calculateDelta(
                            props.leaderboardEntries[infoType === "Leader" ? 0 : infoType === "You" ? placement : Math.max(i - 1, 0)].time,
                            entry.time,
                            props.onlyHundreths
                          )}
                    </ListItemCell>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
