import { CSSProperties } from "react";

interface NumberPlateProps {
  number: string | number | null | undefined;
  big?: boolean;
  style?: CSSProperties;
}

export function NumberPlate(props: NumberPlateProps) {
  return (
    <div style={{ width: "min-content" }}>
      <div
        style={{
          border: "1px solid black",
          borderRadius: props.big ? "8px" : "6px",
          fontSize: props.big ? "16px" : "12px",
          padding: props.big ? "6px 9px 6px 6px" : "3px 4px 3px 3px",
          textAlign: "center",
          background: "white",
          width: props.big ? undefined : "20px",
          minWidth: props.big ? "20px" : undefined,
          whiteSpace: "nowrap",
          fontStyle: "italic",
          ...props.style,
        }}
      >
        {props.number ? props.number : " - "}
      </div>
    </div>
  );
}
