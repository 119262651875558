import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { inputToLapTimeString, timeInputIsValid } from "../../lib/functions";

interface TimeInputProps {
  value: string;
  onChange(value: string): void;
  onlyHudreths?: boolean;
}

export function TimeInput(props: TimeInputProps) {
  const { setIsInputting } = useContext(DataContext);

  return (
    <input
      value={props.value}
      type="tel"
      onChange={(e) =>
        timeInputIsValid(e.target.value, props.onlyHudreths) && props.onChange(inputToLapTimeString(e.target.value, props.onlyHudreths))
      }
      style={{
        fontSize: "36px",
        width: "190px",
        border: "none",
        padding: "4px",
        borderBottom: "1px solid #c4c4c4",
        borderRadius: 0,
        background: "none",
        textAlign: "center",
        outline: "none",
        color: "#5d5d5d",
      }}
      onFocus={() => setIsInputting(true)}
      onBlur={() => setIsInputting(false)}
    />
  );
}
