import { useContext, useEffect, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory, useParams, useLocation } from "react-router";
import { Button } from "../components/general/button";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import { calculateLeaderboardEntries, splitStintsInKartTypes, getPrettyTrackName, msToLaptime, backUrl } from "../lib/functions";
import { Leaderboard, Track } from "../lib/models";
import { ButtonBar } from "../components/general/buttonBar";
import { DOMAIN, LF_SCROLL_POS_TRACK, TRACKS } from "../lib/definitions";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { Header } from "../components/general/header";
import { Tag } from "../components/general/tag";
import { SectionContainer } from "../components/general/sectionContainer";
import { InfoItem } from "../components/general/infoItem";
import { LeaderboardCardContent } from "../components/leaderboardCardContent";
import { TrackScreenCard } from "../components/trackScreenCard";
import { SessionsCardContent } from "../components/sessionsCardContent";
import { StintsCardContent } from "../components/stintsCardContent";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { OnboardsCardContent } from "../components/onboardsCardContent";

export function TrackScreen() {
  const [track, setTrack] = useState<Track>();
  const { trackId, layoutId } = useParams<{ trackId: string; layoutId: string }>();
  const { sessions, allStints, isWorking, setCurrentNavItem, userInfo } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  useEffect(() => {
    setTrack(TRACKS.find((t) => t.id === trackId));
  }, [trackId]);

  const kartTypeStints = useMemo(() => {
    const trackStints = allStints.filter((s) => s.session.track === trackId && s.session.layout + "" === layoutId);
    return splitStintsInKartTypes(trackStints, TRACKS.find((t) => t.id === trackId)?.kartTypes);
  }, [allStints, trackId, layoutId]);

  const leaderboards: Leaderboard[] = useMemo(
    () => kartTypeStints.map(({ kartType, stints }) => ({ kartType: kartType, leaderboardEntries: calculateLeaderboardEntries(stints) })),
    [kartTypeStints]
  );

  const longestLeaderboardLength = useMemo(
    () => leaderboards.reduce((prev, curr) => Math.max(prev, curr.leaderboardEntries.length), 0),
    [leaderboards]
  );

  const trackSessions = useMemo(
    () => sessions.filter((s) => s.track === trackId && s.layout + "" === layoutId).sort((a, b) => b.timestamp - a.timestamp),
    [sessions, trackId, layoutId]
  );
  const trackStints = useMemo(
    () =>
      allStints
        .filter((s) => s.session.track === trackId && s.session.layout + "" === layoutId)
        .sort((a, b) => b.session.timestamp - a.session.timestamp),
    [allStints, trackId, layoutId]
  );

  const myTrackStintsLength = useMemo(() => trackStints.filter((s) => s.driver === userInfo.email).length, [trackStints, userInfo]);

  const tracksStintsWithOnboards = useMemo(() => trackStints.filter((s) => s.youtubeUrls.length > 0), [trackStints]);
  const layout = useMemo(() => track?.layouts.find((l) => l.id + "" === layoutId), [track, layoutId]);
  const layoutLength = useMemo(() => track?.layouts.find((l) => l.id + "" === layoutId)?.length, [track, layoutId]);

  const onboardVideo = useMemo(() => track?.layouts.find((l) => l.id + "" === layoutId)?.onboardVideo, [layoutId, track?.layouts]);

  const inactiveLayouts = useMemo(() => track?.layouts.filter((l) => !l.current) || [], [track]);

  return (
    <ScrollableContainer id={LF_SCROLL_POS_TRACK}>
      {track && (
        <>
          <TrackLogoBanner trackId={trackId} layout={layoutId} />
          {track.layouts.length > 1 && !layoutId ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1>Choose layout</h1>
              <div style={{ display: "grid", rowGap: "12px", justifyContent: "stretch" }}>
                {track.layouts
                  .filter((l) => l.current)
                  .map((layout) => (
                    <div key={layout.id}>
                      <Button
                        text={layout.name}
                        style={{ display: "block", textAlign: "center" }}
                        onClick={() => history.push(`/tracks/${trackId}/${layout.id}`)}
                      />
                    </div>
                  ))}
              </div>
              {inactiveLayouts.length > 0 && (
                <div style={{ marginTop: 24 }}>
                  <div style={{ margin: "8px 0" }}>Inactive layouts</div>
                  <div style={{ display: "grid", rowGap: "12px", justifyContent: "stretch" }}>
                    {inactiveLayouts.map((layout) => (
                      <div key={layout.id}>
                        <Button
                          text={layout.name}
                          style={{ display: "block", textAlign: "center" }}
                          onClick={() => history.push(`/tracks/${trackId}/${layout.id}`)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {track.layouts.length > 1 && (
                <div style={{ display: "flex", overflowX: "auto", paddingLeft: "16px", marginTop: "24px" }}>
                  {track.layouts.map((layout) => (
                    <Tag
                      key={layout.id}
                      text={layout.name}
                      style={{
                        display: "block",
                        width: "min-content",
                        whiteSpace: "nowrap",
                      }}
                      outline={layoutId !== layout.id + ""}
                      onClick={() => history.push(`/tracks/${trackId}/${layout.id}`)}
                    />
                  ))}
                  <div style={{ visibility: "hidden", minWidth: "20px" }} />
                </div>
              )}
              <Header title={getPrettyTrackName(track.id, layoutId)} level={1} onBackUrl={"/tracks"} smallTopMargin />
              {layout?.current && (
                <ButtonBar style={{ marginBottom: 8 }}>
                  <Button
                    text="New session"
                    icon={<PlusOutlined />}
                    onClick={() => history.push(`/tracks/${trackId}/${layoutId}/new-session?${backUrl(location)}`)}
                  />
                </ButtonBar>
              )}
              <SectionContainer style={{ fontSize: "20px" }}>
                <InfoItem label="Location" value={track.location} />
                <InfoItem label="Track length" value={layoutLength ? layoutLength + " meters" : "No information"} />
                <InfoItem label="Number of sessions" value={trackSessions.length} />
                <InfoItem label="Number of stints" value={trackStints.length} />
                <InfoItem
                  label="Kart types"
                  value={
                    <div style={{ display: "flex" }}>
                      {TRACKS.find((t) => t.id === trackId)?.kartTypes.map((kt) => (
                        <Tag key={kt} text={kt} marginSide="right" style={{ marginRight: "8px" }} />
                      ))}
                    </div>
                  }
                />
                <InfoItem label="Active" value={layout?.current ? "Yes" : "No"} />
              </SectionContainer>
              <div style={{ margin: "48px 16px 16px 16px" }}>
                <TrackScreenCard
                  title={longestLeaderboardLength === 0 ? "Leaderboards" : `Leaderboards (${longestLeaderboardLength})`}
                  onClick={() => history.push(`/tracks/${trackId}/${layoutId}/leaderboards`)}
                  className="purple-gradient"
                  spin={isWorking && leaderboards.length === 0}
                >
                  <LeaderboardCardContent leaderboards={leaderboards} />
                </TrackScreenCard>
                <TrackScreenCard
                  title={trackSessions.length === 0 ? "Sessions" : `Sessions (${trackSessions.length})`}
                  onClick={() => history.push(`/tracks/${trackId}/${layoutId}/sessions`)}
                  className="orange-gradient"
                  spin={isWorking && trackSessions.length === 0}
                  style={{ marginTop: "24px" }}
                >
                  <SessionsCardContent trackSessions={trackSessions} />
                </TrackScreenCard>
                <TrackScreenCard
                  title={myTrackStintsLength === 0 ? "Your stints" : `Your stints (${myTrackStintsLength})`}
                  onClick={() => history.push(`/tracks/${trackId}/${layoutId}/stints`)}
                  className="green-gradient"
                  spin={isWorking && trackSessions.length === 0}
                  style={{ marginTop: "24px" }}
                >
                  <StintsCardContent trackStints={trackStints} />
                </TrackScreenCard>
                <TrackScreenCard
                  title={tracksStintsWithOnboards.length === 0 ? "Onboards" : `Onboards (${tracksStintsWithOnboards.length})`}
                  onClick={() => history.push(`/tracks/${trackId}/${layoutId}/onboards`)}
                  className="gold-gradient"
                  spin={isWorking && trackSessions.length === 0}
                  style={{ marginTop: "24px" }}
                >
                  <OnboardsCardContent trackStintsWithOnboards={tracksStintsWithOnboards} />
                </TrackScreenCard>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "48px",
                    borderRadius: "24px",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent wrapperStyle={{ borderRadius: "24px" }} contentStyle={{ borderRadius: "24px" }}>
                      <img
                        src={`/assets/${track.id}-layout-${layoutId}.png`}
                        alt=""
                        style={{
                          width: "100%",

                          alignSelf: "flex-start",
                          borderRadius: "24px",
                        }}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                {onboardVideo && (
                  <div style={{ marginTop: "48px" }}>
                    <div style={{ margin: "12px", textAlign: "center" }}>
                      <div>Hotlap at {getPrettyTrackName(trackId, layoutId)} by</div>
                      <div style={{ marginTop: "4px" }}>
                        {onboardVideo.driver} - {msToLaptime([onboardVideo.laptime])}
                      </div>
                    </div>
                    <iframe
                      id="ytplayer"
                      title="ytplayer"
                      //@ts-ignore
                      type="text/html"
                      width="100%"
                      height="240px"
                      src={`https://www.youtube.com/embed/${onboardVideo.id}?origin=http://gokart.${DOMAIN}`}
                      frameBorder="0"
                      style={{ borderRadius: "24px", boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}
                      allowFullScreen
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </ScrollableContainer>
  );
}
