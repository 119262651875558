import { CSSProperties } from "react";
import { toDateAndTime } from "../lib/functions";

interface TimeStampAsDateProps {
  timestamp: number;
  excludeTime?: boolean;
  onlyYear?: boolean;
  withYear?: boolean;
  style?: CSSProperties;
}

export function TimeStampAsDate(props: TimeStampAsDateProps) {
  return (
    <div
      style={{
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "15px",
        ...props.style,
      }}
    >
      {toDateAndTime(props.timestamp, props.excludeTime || props.withYear, props.excludeTime, props.onlyYear)}
    </div>
  );
}
