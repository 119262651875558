import React from "react";
import { FilterOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/general/button";
import { ButtonBar } from "../components/general/buttonBar";
import { TimeStampAsDate } from "../components/timestampAsDate";
import { DataContext } from "../lib/contexts";
import {
  msToLaptime,
  getPrettyTrackName,
  splitItemsIntoYearAndDay,
  tagSorter,
  toDateAndTime,
  removeBorderOnMiddleItem,
  calculateTotalLaptime,
} from "../lib/functions";
import * as localForage from "localforage";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { Header } from "../components/general/header";
import { Spinner } from "../components/general/spinner";
import { ListItemCell } from "../components/general/listItemCell";
import { Tag } from "../components/general/tag";
import { Stint, StintExtended } from "../lib/models";
import { conditionTags, kartTags, LF_SCROLL_POS_STINTS, LF_STINTS_SHOW_LAPTIME, raceTags, TRACKS } from "../lib/definitions";
import { SectionContainer } from "../components/general/sectionContainer";
import { Slider, Switch } from "antd";
import { RadioGroup } from "../components/general/radioGroup";
import { MdMovie } from "react-icons/md";

export function StintsScreen() {
  const [showLaptime, setShowLaptime] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredStints, setFilteredStints] = useState<StintExtended[]>([]);
  const [calculatedWeightLimits, setCalculatedWeightLimits] = useState<[number, number]>([0, 200]);
  const [calculatedTimeLimits, setCalculatedTimeLimits] = useState<[number, number]>([0, 180000]);
  const [calculatedDateLimits, setCalculatedDateLimits] = useState<[number, number]>([0, 0]);
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  // filter
  const [tracks, setTracks] = useState<string[]>([]);
  const [drivers, setDrivers] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [oneOfTags, setOneOfTags] = useState(true);
  const [oneOfDrivers, setOneOfDrivers] = useState(true);
  const [weightLimits, setWeightLimits] = useState<[number, number]>([0, 200]);
  const [timeLimits, setTimeLimits] = useState<[number, number]>([0, 1800000]);
  const [dateLimits, setDateLimits] = useState<[number, number]>([0, 0]);

  const { myStints, userInfo, getDriverName, isWorking, setCurrentNavItem } = useContext(DataContext);

  const history = useHistory();

  useEffect(() => {
    localForage.getItem(LF_STINTS_SHOW_LAPTIME, (_err, val) => setShowLaptime(!!val));
  }, []);

  useEffect(() => setCurrentNavItem("Stint"), [setCurrentNavItem]);

  useEffect(() => {
    localForage.setItem(LF_STINTS_SHOW_LAPTIME, showLaptime);
  }, [showLaptime]);

  useEffect(() => {
    let newFilteredStints = myStints;

    if (tracks.length > 0) {
      newFilteredStints = newFilteredStints.filter((s) => tracks.includes(s.session.track));
    }
    if (tags.length > 0) {
      if (oneOfTags) {
        newFilteredStints = newFilteredStints.filter(
          (s) => new Set([...tags, ...s.session.tags]).size < tags.length + s.session.tags.length
        );
      } else {
        newFilteredStints = newFilteredStints.filter((s) => new Set([...tags, ...s.session.tags]).size === s.session.tags.length);
      }
    }
    if (drivers.length > 0) {
      if (oneOfDrivers) {
        newFilteredStints = newFilteredStints.filter((s) => new Set([...drivers, ...s.drivers]).size < drivers.length + s.drivers.length);
      } else {
        newFilteredStints = newFilteredStints.filter((s) => new Set([...drivers, ...s.drivers]).size === s.drivers.length);
      }
    }

    let weightMin = Number.MAX_SAFE_INTEGER;
    let weightMax = Number.MIN_SAFE_INTEGER;
    let timeMin = Number.MAX_SAFE_INTEGER;
    let timeMax = Number.MIN_SAFE_INTEGER;
    newFilteredStints.forEach((s) => {
      weightMin = Math.min(weightMin, s.weight);
      weightMax = Math.max(weightMax, s.weight);
      const time = calculateTotalLaptime(s.time);
      timeMin = Math.min(timeMin, time);
      timeMax = Math.max(timeMax, time);
    });
    if (newFilteredStints.length > 0) {
      const newWeightLimits = weightLimits;
      const newTimeLimits = timeLimits;
      if (weightLimits[0] === calculatedWeightLimits[0]) newWeightLimits[0] = weightMin;
      if (weightLimits[1] === calculatedWeightLimits[1]) newWeightLimits[1] = weightMax;
      if (timeLimits[0] === calculatedTimeLimits[0]) newTimeLimits[0] = timeMin;
      if (timeLimits[1] === calculatedTimeLimits[1]) newTimeLimits[1] = timeMax;

      if (JSON.stringify(newWeightLimits) !== JSON.stringify(weightLimits)) {
        setWeightLimits(newWeightLimits);
      }
      if (JSON.stringify(newTimeLimits) !== JSON.stringify(timeLimits)) {
        setTimeLimits(newTimeLimits);
      }
      if (JSON.stringify(calculatedWeightLimits) !== JSON.stringify([weightMin, weightMax])) {
        setCalculatedWeightLimits([weightMin, weightMax]);
      }
      if (JSON.stringify(calculatedTimeLimits) !== JSON.stringify([timeMin, timeMax])) {
        setCalculatedTimeLimits([timeMin, timeMax]);
      }
    }

    newFilteredStints = newFilteredStints.filter((s) => {
      const time = calculateTotalLaptime(s.time);
      return (
        s.weight >= weightLimits[0] &&
        s.weight <= weightLimits[1] &&
        time >= timeLimits[0] &&
        time <= timeLimits[1] &&
        s.session.timestamp >= dateLimits[0] &&
        s.session.timestamp <= dateLimits[1]
      );
    });

    setFilteredStints(newFilteredStints);
  }, [
    myStints,
    tracks,
    tags,
    oneOfTags,
    weightLimits,
    timeLimits,
    drivers,
    oneOfDrivers,
    calculatedWeightLimits,
    calculatedTimeLimits,
    dateLimits,
  ]);

  useEffect(() => {
    const newWeightLimits = weightLimits;
    if (weightLimits[0] < calculatedWeightLimits[0]) {
      newWeightLimits[0] = calculatedWeightLimits[0];
    }
    if (weightLimits[1] > calculatedWeightLimits[1]) {
      newWeightLimits[1] = calculatedWeightLimits[1];
    }

    if (JSON.stringify(newWeightLimits) !== JSON.stringify(weightLimits)) {
      setWeightLimits(newWeightLimits);
    }
  }, [calculatedWeightLimits, weightLimits]);

  useEffect(() => {
    const newTimeLimits = timeLimits;
    if (timeLimits[0] < calculatedTimeLimits[0]) {
      newTimeLimits[0] = calculatedTimeLimits[0];
    }
    if (timeLimits[1] > calculatedTimeLimits[1]) {
      newTimeLimits[1] = calculatedTimeLimits[1];
    }

    if (JSON.stringify(newTimeLimits) !== JSON.stringify(timeLimits)) {
      setTimeLimits(newTimeLimits);
    }
  }, [calculatedTimeLimits, timeLimits]);

  useEffect(() => {
    const newDateLimits: [number, number] = [dateLimits[0], dateLimits[1]];
    if (dateLimits[0] === 0) {
      newDateLimits[0] = calculatedDateLimits[0];
    }
    if (dateLimits[1] === 0) {
      newDateLimits[1] = calculatedDateLimits[1];
    }

    if (JSON.stringify(newDateLimits) !== JSON.stringify(dateLimits)) {
      setDateLimits(newDateLimits);
    }
  }, [calculatedDateLimits, dateLimits]);

  useEffect(() => {
    if (myStints.length > 0) {
      let earliestDate = Number.MAX_SAFE_INTEGER;
      let latestDate = Number.MIN_SAFE_INTEGER;

      myStints.forEach((s) => {
        earliestDate = Math.min(earliestDate, s.session.timestamp);
        latestDate = Math.max(latestDate, s.session.timestamp);
      });

      setCalculatedDateLimits([earliestDate - 86_400_000, latestDate + 86_400_000]);
    }
  }, [myStints]);

  const stintsSplitIntoYear = useMemo(
    () =>
      splitItemsIntoYearAndDay(
        myStints
          .filter((s) => !onlyFavorites || s.favorited)
          .map((s: any) => ({ ...s, timestamp: s.session.timestamp, trackName: getPrettyTrackName(s.session.track, s.session.layout) }))
      ),
    [myStints, onlyFavorites]
  );

  const filteredStintsSplitIntoYear = useMemo(
    () =>
      splitItemsIntoYearAndDay(
        filteredStints
          .filter((s) => !onlyFavorites || s.favorited)
          .map((s: any) => ({ ...s, trackName: getPrettyTrackName(s.session.track, s.session.layout) }))
      ),
    [filteredStints, onlyFavorites]
  );

  const allDrivers = useMemo(() => {
    const driverSet = new Set<string>();
    myStints.forEach((s) => s.drivers.forEach((d) => driverSet.add(d)));
    return Array.from(driverSet.keys())
      .filter((driver) => driver !== userInfo.email)
      .sort();
  }, [myStints, userInfo.email]);

  const relevantStints = showFilter ? filteredStintsSplitIntoYear : stintsSplitIntoYear;

  function onStintClick(s: Stint) {
    history.push(`/stints/${s._id}?back-url=${encodeURIComponent("/stints")} `);
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_STINTS}>
      <Header level={1} title="My Stints" />
      <ButtonBar>
        <Button
          text={`${showFilter ? "Hide" : "Show"} filters`}
          icon={<FilterOutlined />}
          onClick={() => setShowFilter(!showFilter)}
          style={{ marginRight: "8px" }}
        />
        <Button text="New stint" icon={<PlusOutlined />} onClick={() => history.push("/stints/new-stint")} />
      </ButtonBar>
      {showFilter && (
        <div>
          <Header
            level={3}
            title="Tracks"
            smallTopMargin
            extra={
              <div onClick={() => setTracks([])}>
                {"Reset"}
                <ReloadOutlined style={{ marginLeft: "8px" }} />
              </div>
            }
          />
          <SectionContainer>
            <div style={{ textAlign: "center", marginBottom: "8px" }}>
              {TRACKS.sort().map((track) => (
                <Tag
                  key={track.id}
                  text={track.name}
                  outline={!tracks.includes(track.id)}
                  style={{ marginBottom: "4px" }}
                  onClick={() => {
                    if (tracks.includes(track.id)) {
                      setTracks((ts) => ts.filter((t) => t !== track.id));
                    } else {
                      setTracks(tracks.concat(track.id));
                    }
                  }}
                />
              ))}
            </div>
          </SectionContainer>
          <Header
            level={3}
            title="Date"
            extra={
              <div onClick={() => setDateLimits(calculatedDateLimits)}>
                {"Reset"}
                <ReloadOutlined style={{ marginLeft: "8px" }} />
              </div>
            }
            smallTopMargin
          />
          <SectionContainer>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "-17px" }}>
              <div>{`${toDateAndTime(dateLimits[0], true, true)} - ${toDateAndTime(dateLimits[1], true, true)}`}</div>
            </div>
            <div style={{ fontSize: "12px", fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
              <div>{toDateAndTime(calculatedDateLimits[0], true, true)}</div>
              <div>{toDateAndTime(calculatedDateLimits[1], true, true)}</div>
            </div>
            <Slider
              range
              step={86_000_000}
              min={calculatedDateLimits[0]}
              max={calculatedDateLimits[1]}
              value={dateLimits}
              tipFormatter={null}
              onChange={(v) => setDateLimits(v)}
            />
          </SectionContainer>
          <Header
            level={3}
            title="Drivers"
            extra={
              <div
                onClick={() => {
                  setOneOfDrivers(true);
                  setDrivers([]);
                }}
              >
                {"Reset"}
                <ReloadOutlined style={{ marginLeft: "8px" }} />
              </div>
            }
          />
          <SectionContainer>
            <RadioGroup
              options={[
                { label: "One of", value: true },
                { label: "All of", value: false },
              ]}
              currentValue={oneOfDrivers}
              onClick={setOneOfDrivers}
              style={{ marginBottom: "12px" }}
            />
            <div style={{ textAlign: "center", marginBottom: "8px" }}>
              {allDrivers
                .sort((a, b) => getDriverName(a).localeCompare(getDriverName(b)))
                .map((driver) => (
                  <Tag
                    key={driver}
                    text={getDriverName(driver)}
                    outline={!drivers.includes(driver)}
                    style={{ marginBottom: "4px" }}
                    onClick={() => {
                      if (drivers.includes(driver)) {
                        setDrivers((ds) => ds.filter((d) => d !== driver));
                      } else {
                        setDrivers(drivers.concat(driver));
                      }
                    }}
                  />
                ))}
            </div>
          </SectionContainer>
          <Header
            level={3}
            title="Tags"
            extra={
              <div
                onClick={() => {
                  setOneOfTags(true);
                  setTags([]);
                }}
              >
                {"Reset"}
                <ReloadOutlined style={{ marginLeft: "8px" }} />
              </div>
            }
          />
          <SectionContainer>
            <RadioGroup
              options={[
                { label: "One of", value: true },
                { label: "All of", value: false },
              ]}
              currentValue={oneOfTags}
              onClick={setOneOfTags}
              style={{ marginBottom: "12px" }}
            />
            <div style={{ textAlign: "center", marginBottom: "8px" }}>
              {raceTags.map((t) => (
                <Tag
                  key={t.text}
                  text={t.text}
                  outline={!tags.includes(t.text)}
                  style={{ marginBottom: "4px" }}
                  onClick={() => {
                    if (tags.includes(t.text)) {
                      setTags((ts) => ts.filter((trackId) => trackId !== t.text));
                    } else {
                      setTags(tags.concat(t.text));
                    }
                  }}
                />
              ))}
            </div>
            <div style={{ textAlign: "center", marginBottom: "8px" }}>
              {kartTags.map((t) => (
                <Tag
                  key={t.text}
                  text={t.text}
                  outline={!tags.includes(t.text)}
                  style={{ marginBottom: "4px" }}
                  onClick={() => {
                    if (tags.includes(t.text)) {
                      setTags((ts) => ts.filter((trackId) => trackId !== t.text));
                    } else {
                      setTags(tags.concat(t.text));
                    }
                  }}
                />
              ))}
            </div>
            <div style={{ textAlign: "center" }}>
              {conditionTags.map((t) => (
                <Tag
                  key={t.text}
                  text={t.text}
                  outline={!tags.includes(t.text)}
                  style={{ marginBottom: "4px" }}
                  onClick={() => {
                    if (tags.includes(t.text)) {
                      setTags((ts) => ts.filter((trackId) => trackId !== t.text));
                    } else {
                      setTags(tags.concat(t.text));
                    }
                  }}
                />
              ))}
            </div>
          </SectionContainer>
          <Header
            level={3}
            title="Lap time & Weight"
            extra={
              <div
                onClick={() => {
                  setWeightLimits(calculatedWeightLimits);
                  setTimeLimits(calculatedTimeLimits);
                }}
              >
                {"Reset"}
                <ReloadOutlined style={{ marginLeft: "8px" }} />
              </div>
            }
          />
          <SectionContainer>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "-17px" }}>
              <div>{`${weightLimits[0]}kg - ${weightLimits[1]}kg`}</div>
            </div>
            <div style={{ fontSize: "12px", fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
              <div>{calculatedWeightLimits[0]}</div>
              <div>{calculatedWeightLimits[1]}</div>
            </div>
            <Slider
              range
              min={calculatedWeightLimits[0]}
              max={calculatedWeightLimits[1]}
              value={weightLimits}
              tipFormatter={null}
              onChange={(v) => setWeightLimits(v)}
            />
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "-17px" }}>
              <div>{`${msToLaptime([timeLimits[0]])} - ${msToLaptime([timeLimits[1]])}`}</div>
            </div>
            <div style={{ fontSize: "12px", fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
              <div>{msToLaptime([calculatedTimeLimits[0]])}</div>
              <div>{msToLaptime([calculatedTimeLimits[1]])}</div>
            </div>
            <Slider
              range
              min={calculatedTimeLimits[0]}
              max={calculatedTimeLimits[1]}
              value={timeLimits}
              tipFormatter={null}
              onChange={(v) => setTimeLimits(v)}
            />
          </SectionContainer>
          <div
            style={{
              textAlign: "center",
              margin: "32px 32px 16px 32px",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              {filteredStints.length + ` result${filteredStints.length !== 1 ? "s" : ""}`}
            </div>
          </div>
          <div style={{ borderBottom: `1px solid lightgray`, margin: "0 24px" }} />
        </div>
      )}
      <div
        style={{ display: "grid", gridTemplateColumns: "auto min-content", margin: "12px 24px", alignItems: "center", columnGap: "24px" }}
      >
        <RadioGroup
          options={[
            { label: "Lap time", value: true },
            { label: "Tags", value: false },
          ]}
          currentValue={showLaptime}
          onClick={setShowLaptime}
        />
        <Switch
          style={{ marginRight: "8px" }}
          checkedChildren="Favorites"
          unCheckedChildren="Favorites"
          checked={onlyFavorites}
          onClick={() => setOnlyFavorites(!onlyFavorites)}
        />
      </div>

      {isWorking && relevantStints.length === 0 ? (
        <Spinner />
      ) : relevantStints.length === 0 ? (
        <div className="placeholder" style={{ marginTop: "32px" }}>
          No stints...
        </div>
      ) : (
        relevantStints.map((ys) => (
          <div key={ys.year}>
            <div className="list-header">{ys.year}</div>
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content min-content",
              }}
            >
              {ys.items.map((stintsOnADay) =>
                stintsOnADay.map((s: any, index: number) => (
                  <React.Fragment key={s._id}>
                    <ListItemCell
                      space={8}
                      align="center"
                      style={{ ...removeBorderOnMiddleItem(stintsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      innerStyle={{ paddingRight: "1px" }}
                      onClick={() => onStintClick(s)}
                    >
                      <TimeStampAsDate timestamp={s.session.timestamp} style={{ fontSize: "12px" }} />
                    </ListItemCell>
                    <ListItemCell
                      style={{ ...removeBorderOnMiddleItem(stintsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      onClick={() => onStintClick(s)}
                    >
                      {s.trackName}
                    </ListItemCell>
                    <ListItemCell
                      align="end"
                      style={{
                        ...removeBorderOnMiddleItem(stintsOnADay, index),
                      }}
                      onClick={() => onStintClick(s)}
                    >
                      <MdMovie style={{ fontSize: 18, marginBottom: -4, visibility: s.youtubeUrls.length === 0 ? "hidden" : undefined }} />
                    </ListItemCell>
                    <ListItemCell
                      space={8}
                      align="end"
                      style={{
                        fontSize: "14px",
                        ...removeBorderOnMiddleItem(stintsOnADay, index),
                      }}
                      onClick={() => onStintClick(s)}
                    >
                      {showLaptime ? (
                        <div style={{ fontSize: "14px", textAlign: "end", color: s.favorited ? "#d0af00" : undefined }}>
                          {msToLaptime(s.time)}
                        </div>
                      ) : (
                        s.session.tags.sort(tagSorter).map((t: string) => <Tag key={t} text={t} noText />)
                      )}
                    </ListItemCell>
                    {index === stintsOnADay.length - 1 && <div style={{ gridColumn: "span 4", height: "8px" }} />}
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        ))
      )}
    </ScrollableContainer>
  );
}
