import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Header } from "../components/general/header";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { SectionContainer } from "../components/general/sectionContainer";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import {
  calculateDelta,
  calculateGokartComparisonLaptimes,
  calculatePlacement,
  calculateTotalLaptime,
  getPrettyTrackName,
  msToLaptime,
  removeBorderOnMiddleItem,
  roundTimestamp,
  tagSorter,
  timestampToPrettyDate,
  timestampToTime,
} from "../lib/functions";
import { InfoType, Session, Stint, StintExtended } from "../lib/models";
import { ListItemCell } from "../components/general/listItemCell";
import { Tag } from "../components/general/tag";
import { BLUE, DOMAIN, LF_INFO_TYPE, LF_SCROLL_POS_EVENTOVERVIEW } from "../lib/definitions";
import { NumberPlate } from "../components/numberplate";
import { RadioGroup } from "../components/general/radioGroup";
import * as localForage from "localforage";
import { Title } from "../components/general/title";
import { MdMovie } from "react-icons/md";

export function EventOverviewScreen() {
  const [session, setSession] = useState<Session>();
  const [sessionsOnTheDay, setSessionsOnTheDay] = useState<Session[]>([]);
  const [infoType, setInfoType] = useState<InfoType>("Weight");

  const { sessions, userInfo, getDriverName, setCurrentNavItem } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();
  const { sessionId } = useParams<{ sessionId: string }>();

  const placement = useMemo(() => calculatePlacement(session, userInfo.email), [session, userInfo.email]);

  useEffect(() => {
    if (placement === -1) {
      setInfoType("Weight");
    } else {
      localForage.getItem(LF_INFO_TYPE, (_, val) => setInfoType((val as InfoType) || "Weight"));
    }
  }, [placement]);

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  useEffect(() => {
    if (!session) {
      return;
    }

    const [lowerBound, upperBound] = roundTimestamp(session?.timestamp);

    setSessionsOnTheDay(
      sessions
        .filter((s) => lowerBound <= s.timestamp && s.timestamp <= upperBound && s.track === session.track && s.layout === session.layout)
        .map((session) => {
          const placement = calculatePlacement(session, userInfo.email) + 1;
          return { ...session, placement: placement === 0 ? session.stints.length : placement + "/" + session.stints.length };
        })
        .reverse()
    );
  }, [session, sessions, userInfo.email]);

  useEffect(() => {
    setSession(sessions.find((s) => s._id === sessionId));
  }, [sessionId, sessions]);

  const gokartComparisonLaptimes = useMemo(() => calculateGokartComparisonLaptimes(sessionsOnTheDay), [sessionsOnTheDay]);
  const absoluteLaptimes = useMemo(() => {
    const driverLaptimes: { [k in string]: any } = {};
    sessionsOnTheDay.forEach((session) =>
      session.stints.forEach((s) => {
        if (!driverLaptimes[s.driver]) {
          driverLaptimes[s.driver] = { ...s, session: session };
        } else {
          const time = calculateTotalLaptime(s.time);
          if (time < calculateTotalLaptime(driverLaptimes[s.driver].time)) {
            driverLaptimes[s.driver] = { ...s, session: session };
          }
        }
      })
    );
    return Object.values(driverLaptimes).sort((a, b) => calculateTotalLaptime(a.time) - calculateTotalLaptime(b.time)) as StintExtended[];
  }, [sessionsOnTheDay]);

  const youtubeUrls = useMemo(
    () =>
      sessionsOnTheDay.reduce<string[]>(
        (prev, curr) => prev.concat(...curr.stints.reduce<string[]>((prev, curr) => prev.concat(...curr.youtubeUrls), [])),
        []
      ),
    [sessionsOnTheDay]
  );

  function onSessionCellClick(session: Session) {
    history.push(`/sessions/${session._id}?back-url=${location.pathname + location.search}`);
  }

  function onCellClick(stint: Stint) {
    history.push(`/sessions/${stint.sessionId}?back-url=${location.pathname + location.search}`);
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_EVENTOVERVIEW}>
      <TrackLogoBanner trackId={session?.track} layout={session?.layout} />
      <Header level={1} title="Event overview" />
      <SectionContainer style={{ textAlign: "center" }}>
        Event overview at {getPrettyTrackName(session?.track, session?.layout)} for the {sessionsOnTheDay.length} session
        {sessionsOnTheDay.length > 1 ? "s" : ""} on
        {" " + timestampToPrettyDate(session?.timestamp, true)}
      </SectionContainer>
      <div
        style={{
          display: "grid",
          gridAutoRows: "min-content",
          gridTemplateColumns: "min-content min-content auto min-content min-content",
          marginTop: "16px",
        }}
      >
        {sessionsOnTheDay.map((s: any, index) => (
          <React.Fragment key={s._id}>
            <ListItemCell
              space={[10, 0]}
              align="center"
              style={{ ...removeBorderOnMiddleItem(sessionsOnTheDay, index), color: s.favorited ? "#d0af00" : undefined }}
              onClick={() => onSessionCellClick(s)}
            >
              {s.stints.findIndex((s: Stint) => s.driver === userInfo.email) !== -1 && (
                <div style={{ background: BLUE, height: "6px", width: "6px", borderRadius: "4px" }} />
              )}
            </ListItemCell>
            <ListItemCell
              space={8}
              align="center"
              style={{ ...removeBorderOnMiddleItem(sessionsOnTheDay, index), fontSize: "12px" }}
              innerStyle={{ paddingRight: "1px" }}
              onClick={() => onSessionCellClick(s)}
            >
              {timestampToTime(s.timestamp)}
            </ListItemCell>
            <ListItemCell style={{ ...removeBorderOnMiddleItem(sessionsOnTheDay, index) }} onClick={() => onSessionCellClick(s)}>
              <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                {s.tags
                  .sort((a: string, b: string) => tagSorter(a, b, true))
                  .map((t: any) => (
                    <Tag key={t} text={t} style={{ fontSize: "12px", padding: "3px 6px" }} />
                  ))}
              </div>
            </ListItemCell>
            <ListItemCell
              space={[0, 2]}
              align="end"
              style={{
                ...removeBorderOnMiddleItem(sessionsOnTheDay, index),
              }}
              onClick={() => onSessionCellClick(s)}
            >
              <MdMovie
                style={{
                  fontSize: 18,
                  marginBottom: -4,
                  visibility: s.stints.reduce((prev: boolean, curr: Stint) => prev || curr.youtubeUrls.length > 0, false)
                    ? undefined
                    : "hidden",
                }}
              />
            </ListItemCell>
            <ListItemCell
              space={[8, 14]}
              style={{ ...removeBorderOnMiddleItem(sessionsOnTheDay, index) }}
              onClick={() => onSessionCellClick(s)}
              align="end"
            >
              {s.placement}
            </ListItemCell>
          </React.Fragment>
        ))}
      </div>
      <div style={{ marginTop: "72px" }}>
        {absoluteLaptimes.length > 1 && (
          <RadioGroup
            options={[
              placement !== -1 ? { label: "You", value: "You" } : undefined,
              { label: "Leader", value: "Leader" },
              { label: "Gap", value: "Gap" },
              { label: "Weight", value: "Weight" },
            ]}
            onClick={(v) => {
              setInfoType(v);
              localForage.setItem(LF_INFO_TYPE, v);
            }}
            currentValue={infoType}
            style={{ margin: "24px" }}
          />
        )}
        <Title
          text="Absolute leaderboard"
          style={{ marginBottom: "16px", fontSize: "20px", fontStyle: "normal", marginTop: absoluteLaptimes.length > 1 ? "24px" : "60px" }}
        />
        <div
          style={{
            display: "grid",
            rowGap: "4px",
            gridTemplateColumns: "min-content min-content auto min-content min-content min-content",
            gridAutoRows: "min-content",
          }}
        >
          {absoluteLaptimes.map((s, i) => {
            const date = new Date(s.session.timestamp);
            const timeOfDay = `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
            return (
              <React.Fragment key={s.driver}>
                <>
                  <ListItemCell
                    space={[8, 0]}
                    align="end"
                    onClick={() => onCellClick(s)}
                    style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                  >
                    {i + 1 + "."}
                  </ListItemCell>
                  <ListItemCell
                    space={8}
                    onClick={() => onCellClick(s)}
                    style={{ fontStyle: "italic", color: s.driver === userInfo.email ? "#009eff" : undefined }}
                    innerStyle={{ paddingRight: "1px", fontSize: "12px" }}
                  >
                    {timeOfDay}
                  </ListItemCell>
                  <ListItemCell style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }} onClick={() => onCellClick(s)}>
                    {getDriverName(s.driver)}
                  </ListItemCell>
                  <ListItemCell space={[0, 2]} align="end" onClick={() => onCellClick(s)}>
                    <MdMovie
                      style={{
                        fontSize: 18,
                        marginBottom: -4,
                        visibility: s.youtubeUrls.length > 0 ? undefined : "hidden",
                      }}
                    />
                  </ListItemCell>
                  <ListItemCell
                    space={4}
                    align="end"
                    onClick={() => onCellClick(s)}
                    style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                  >
                    {msToLaptime(s.time)}
                  </ListItemCell>
                  <ListItemCell
                    space={[4, 12]}
                    onClick={() => onCellClick(s)}
                    align="end"
                    style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                  >
                    {infoType === "Weight" || absoluteLaptimes.length === 1
                      ? s.weight
                        ? s.weight + "kg"
                        : "---"
                      : calculateDelta(
                          calculateTotalLaptime(
                            absoluteLaptimes[infoType === "Leader" ? 0 : infoType === "You" ? placement : Math.max(i - 1, 0)].time
                          ),
                          calculateTotalLaptime(s.time)
                        )}
                  </ListItemCell>
                </>
              </React.Fragment>
            );
          })}
        </div>
        {gokartComparisonLaptimes.length > 0 && (
          <Title text="Gokart leaderboards" style={{ marginBottom: "24px", fontSize: "20px", fontStyle: "normal", marginTop: "60px" }} />
        )}
        {gokartComparisonLaptimes.map(({ kartNumber, stints }) => (
          <div key={kartNumber}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
              <NumberPlate number={kartNumber} big />
            </div>
            <div style={{ marginBottom: "36px" }}>
              <div
                style={{
                  display: "grid",
                  rowGap: "4px",
                  gridTemplateColumns: "min-content min-content auto min-content min-content min-content",
                  gridAutoRows: "min-content",
                }}
              >
                {stints.map((s, i) => {
                  const date = new Date(s.session.timestamp);
                  const timeOfDay = `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
                  return (
                    <React.Fragment key={s.driver}>
                      <>
                        <ListItemCell
                          space={[8, 0]}
                          align="end"
                          onClick={() => onCellClick(s)}
                          style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                        >
                          {i + 1 + "."}
                        </ListItemCell>
                        <ListItemCell
                          space={8}
                          onClick={() => onCellClick(s)}
                          style={{ fontStyle: "italic", color: s.driver === userInfo.email ? "#009eff" : undefined }}
                          innerStyle={{ paddingRight: "1px", fontSize: "12px" }}
                        >
                          {timeOfDay}
                        </ListItemCell>
                        <ListItemCell style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }} onClick={() => onCellClick(s)}>
                          {getDriverName(s.driver)}
                        </ListItemCell>
                        <ListItemCell space={[0, 2]} align="end" onClick={() => onCellClick(s)}>
                          <MdMovie
                            style={{
                              fontSize: 18,
                              marginBottom: -4,
                              visibility: s.youtubeUrls.length > 0 ? undefined : "hidden",
                            }}
                          />
                        </ListItemCell>
                        <ListItemCell
                          space={4}
                          align="end"
                          onClick={() => onCellClick(s)}
                          style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                        >
                          {msToLaptime(s.time)}
                        </ListItemCell>
                        <ListItemCell
                          space={[4, 12]}
                          onClick={() => onCellClick(s)}
                          align="end"
                          style={{ color: s.driver === userInfo.email ? "#009eff" : undefined }}
                        >
                          {infoType === "Weight" || stints.length === 1
                            ? s.weight
                              ? s.weight + "kg"
                              : "---"
                            : calculateDelta(
                                calculateTotalLaptime(
                                  stints[infoType === "Leader" ? 0 : infoType === "You" ? placement : Math.max(i - 1, 0)].time
                                ),
                                calculateTotalLaptime(s.time)
                              )}
                        </ListItemCell>
                      </>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
      {youtubeUrls.length > 0 && (
        <React.Fragment>
          <Header level={2} title="Onboards" />
          <div style={{ display: "grid", rowGap: 36, padding: 16 }}>
            {youtubeUrls.map((url) => (
              <iframe
                key={url}
                id="ytplayer"
                title="ytplayer"
                //@ts-ignore
                type="text/html"
                width="100%"
                height="240px"
                src={`https://www.youtube.com/embed/${url.split("/").slice(-1)}?origin=http://gokart.${DOMAIN}`}
                frameBorder="0"
                style={{ borderRadius: "24px", boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}
                allowFullScreen
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </ScrollableContainer>
  );
}
