import { useEffect, useRef, useState } from "react";
import { Button } from "../components/general/button";
import { login } from "../lib/credentialsHandler";
import { DOMAIN } from "../lib/definitions";
import { forceRefresh } from "../lib/functions";

interface LoginScreenProps {
  isWorking: boolean;
  onLogin(): Promise<void>;
  setIsWorking(isWorking: boolean): void;
}

export function LoginScreen(props: LoginScreenProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get("email") || "");
    return () => {
      isMounted.current = false;
    };
  }, []);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  async function tryLogin() {
    try {
      await login(email, password);
      await props.onLogin();
    } catch (error) {
      alert((error as Error).message);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
      <div
        style={{
          fontSize: "48px",
          marginTop: "20%",
          fontWeight: "bold",
          marginBottom: "72px",
        }}
      >
        Gokart
      </div>
      <>
        <div style={{ width: "60%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <input
              placeholder="Email..."
              style={{ marginBottom: "32px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  passwordInputRef.current?.select();
                }
              }}
              autoComplete="new-password"
              className="input"
            />
            <input
              placeholder="Password..."
              style={{ marginBottom: "32px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={passwordInputRef}
              type="password"
              className="input"
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  props.setIsWorking(true);
                  await tryLogin();
                  props.setIsWorking(false);
                }
              }}
              autoComplete="new-password"
            />
          </div>
        </div>
        <Button
          onClick={async () => {
            try {
              props.setIsWorking(true);
              await tryLogin();
            } catch (error) {
              alert((error as Error).message);
            } finally {
              props.setIsWorking(false);
            }
          }}
          text="Login"
          loading={props.isWorking}
          disabled={!email || !password}
          style={{
            margin: "8px 0 36px 0",
          }}
        />
        <h3
          style={{ margin: 0 }}
          onClick={() => (window.location.href = `https://${DOMAIN}/sign-up?redirect=${window.location.origin}/login`)}
        >
          Sign up
        </h3>
        <div
          style={{ marginTop: "16px" }}
          onClick={() => (window.location.href = `https://${DOMAIN}/forgot-password?redirect=${window.location.origin}/login`)}
        >
          Forgot password?
        </div>
      </>
      <img src="/assets/gokart-logo.png" alt="" style={{ width: "100%", marginTop: "72px" }} />
      <div
        style={{
          position: "absolute",
          bottom: "24px",
          right: "24px",
          fontWeight: "bold",
          color: "#9c9c9c",
          fontSize: "18px",
        }}
        onClick={forceRefresh}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}
