import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { BLUE } from "../../lib/definitions";

interface InfoItemProps {
  label: string;
  value: any;
  cancellable?: boolean;
  slim?: boolean;
  editComponent?(onSave: () => void): React.ReactNode;
  onEdit?(): void;
}

export function InfoItem(props: InfoItemProps) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div style={{ marginBottom: "8px" }}>
      <div style={{ fontSize: "12px", color: "#888888", marginBottom: props.slim ? undefined : "6px", whiteSpace: "nowrap" }}>
        {props.label}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ minHeight: props.slim ? undefined : "30px", width: "100%" }}>
          {isEditing ? (
            props.editComponent!(() => setIsEditing(false))
          ) : (
            <div
              style={{
                marginRight: "4px",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {props.value}
            </div>
          )}
        </div>
        {props.onEdit && <EditOutlined style={{ color: BLUE }} onClick={props.onEdit} />}
        {props.editComponent && (
          <>
            {isEditing ? (
              <div>
                <CheckOutlined style={{ color: BLUE }} onClick={() => setIsEditing(false)} />
                {props.cancellable && <DeleteOutlined style={{ color: "red", marginLeft: "16px" }} onClick={() => setIsEditing(false)} />}
              </div>
            ) : (
              <EditOutlined style={{ color: BLUE }} onClick={() => setIsEditing(true)} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
