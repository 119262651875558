import { CSSProperties } from "react";

interface ListItemCellProps {
  children?: React.ReactNode;
  space?: number | [number, number];
  align?: "start" | "center" | "end";
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  className?: string;
  onClick?(): void;
}

export function ListItemCell(props: ListItemCellProps) {
  const paddingLeft = Array.isArray(props.space) ? props.space[0] : props.space || 0;
  const paddingRight = Array.isArray(props.space) ? props.space[1] : props.space || 0;

  return (
    <div
      style={{
        borderBottom: "1px solid #d7dbde",
        borderTop: "1px solid #d7dbde",
        background: "white",
        padding: `8px ${paddingRight}px 8px ${paddingLeft}px`,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...props.style,
      }}
      onClick={props.onClick}
      className={props.className}
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: props.align,
          width: "100%",
          ...props.innerStyle,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
