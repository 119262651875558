import { CSSProperties } from "react";
import { kartTags } from "../../lib/definitions";
import { tagToColor } from "../../lib/functions";

interface TagProps {
  text: string;
  width?: number;
  outline?: boolean;
  color?: string;
  style?: CSSProperties;
  marginSide?: "left" | "right" | "none";
  noText?: boolean;
  onClick?(): void;
}

export function Tag(props: TagProps) {
  const color = props.color || tagToColor(props.text);
  const shouldBeSquare = kartTags.map((t) => t.text).includes(props.text);

  return (
    <div
      style={{
        fontSize: "14px",
        padding: `4px ${props.noText ? 4 : 10}px`,
        borderRadius: shouldBeSquare ? undefined : "8px",
        display: "inline-block",
        border: `1px solid ${color}`,
        background: props.outline ? undefined : color,
        color: props.outline ? color : "white",
        marginRight: props.marginSide !== "none" && props.marginSide === "right" ? "4px" : undefined,
        marginLeft: props.marginSide !== "none" && props.marginSide !== "right" ? "4px" : undefined,
        width: props.width,
        textAlign: "center",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.noText ? "" : props.text}
    </div>
  );
}
