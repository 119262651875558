import { CSSProperties } from "react";
import { FaMedal } from "react-icons/fa";

interface MedalProps {
  type: "Gold" | "Silver" | "Bronze" | "Other";
  style?: CSSProperties;
}

export function Medal(props: MedalProps) {
  return (
    <FaMedal
      style={{
        color: props.type === "Gold" ? "#ffbe00" : props.type === "Silver" ? "#a0a0a0" : props.type === "Bronze" ? "#cc5f00" : undefined,
        marginBottom: "-2px",
        ...props.style,
      }}
    />
  );
}
