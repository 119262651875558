import React, { useEffect } from "react";
import { useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "../components/general/header";
import { ListItemCell } from "../components/general/listItemCell";
import { Tag } from "../components/general/tag";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { TimeStampAsDate } from "../components/timestampAsDate";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import {
  isDd2Session,
  makeLeaderboard,
  msToLaptime,
  removeBorderOnMiddleItem,
  splitItemsIntoYearAndDay,
  tagSorter,
} from "../lib/functions";
import { ButtonBar } from "../components/general/buttonBar";
import { Button } from "../components/general/button";
import { PlusOutlined } from "@ant-design/icons";
import { MdMovie } from "react-icons/md";
import { LF_SCROLL_POS_TRACKSTINTS, TRACKS } from "../lib/definitions";

export function TrackStintsScreen() {
  const { myStints, userInfo, setCurrentNavItem } = useContext(DataContext);
  const { trackId, layoutId } = useParams<{ trackId: string; layoutId: string }>();
  const history = useHistory();

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  const layout = useMemo(() => TRACKS.find((t) => t.id === trackId)?.layouts.find((l) => l.id + "" === layoutId), [trackId, layoutId]);

  const stintsSplitIntoYear = useMemo(
    () =>
      splitItemsIntoYearAndDay(myStints.filter((r) => r.session.track === trackId && r.session.layout + "" === layoutId)).map(
        ({ year, items }) => ({
          year,
          items: items.map((stintsOnADay) =>
            stintsOnADay.map((s: any) => {
              const placement = makeLeaderboard(s.session.stints).findIndex((entry) => entry.driver === userInfo.email) + 1;
              return { ...s, placement: placement === 0 ? s.session.stints.length : placement + "/" + s.session.stints.length };
            })
          ),
        })
      ),
    [myStints, trackId, layoutId, userInfo.email]
  );

  function onStintCellClick(stint: any) {
    history.push(`/sessions/${stint.session._id}`);
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_TRACKSTINTS}>
      <TrackLogoBanner trackId={trackId} layout={layoutId} />
      <Header level={1} title="Your Stints" onBackUrl={`/tracks/${trackId}/${layoutId}`} />
      {layout?.current && (
        <ButtonBar style={{ marginBottom: "30px" }}>
          <Button text="New stint" icon={<PlusOutlined />} onClick={() => history.push(`/stints/new-stint?track=${trackId};${layoutId}`)} />
        </ButtonBar>
      )}
      {stintsSplitIntoYear.length === 0 ? (
        <div style={{ textAlign: "center" }}>You have no registered stints...</div>
      ) : (
        stintsSplitIntoYear.map((ys) => (
          <div key={ys.year}>
            <div className="list-header">{ys.year}</div>
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content min-content min-content",
              }}
            >
              {ys.items.map((stintsOnADay) =>
                stintsOnADay.map((s: any, index: number) => (
                  <React.Fragment key={s._id}>
                    <ListItemCell
                      space={[12, 8]}
                      align="center"
                      style={{ ...removeBorderOnMiddleItem(stintsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      innerStyle={{ paddingRight: "2px" }}
                      onClick={() => onStintCellClick(s)}
                    >
                      <TimeStampAsDate timestamp={s.session.timestamp} style={{ fontSize: "12px" }} />
                    </ListItemCell>
                    <ListItemCell
                      style={{ ...removeBorderOnMiddleItem(stintsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      onClick={() => onStintCellClick(s)}
                    >
                      <div style={{ display: "flex" }}>
                        {s.session.tags
                          .sort((a: string, b: string) => tagSorter(a, b, true))
                          .map((t: string) => (
                            <Tag key={t} text={t} noText />
                          ))}
                      </div>
                    </ListItemCell>
                    <ListItemCell
                      space={8}
                      align="end"
                      style={{
                        ...removeBorderOnMiddleItem(stintsOnADay, index),
                      }}
                      onClick={() => onStintCellClick(s)}
                    >
                      <MdMovie
                        style={{
                          fontSize: 18,
                          marginBottom: -4,
                          visibility: s.youtubeUrls.length > 0 ? undefined : "hidden",
                        }}
                      />
                    </ListItemCell>
                    <ListItemCell
                      space={6}
                      onClick={() => onStintCellClick(s)}
                      style={{ ...removeBorderOnMiddleItem(stintsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      align="end"
                    >
                      {s.placement}
                    </ListItemCell>
                    <ListItemCell
                      space={[8, 12]}
                      align="end"
                      style={{
                        fontSize: "14px",
                        ...removeBorderOnMiddleItem(stintsOnADay, index),
                        color: s.favorited ? "#d0af00" : undefined,
                      }}
                      onClick={() => onStintCellClick(s)}
                    >
                      {msToLaptime(s.time, isDd2Session(s.session))}
                    </ListItemCell>
                    {index === stintsOnADay.length - 1 && <div style={{ gridColumn: "span 5", height: "8px" }} />}
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        ))
      )}
    </ScrollableContainer>
  );
}
