import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { Header } from "../components/general/header";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { ListItemCell } from "../components/general/listItemCell";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { SectionContainer } from "../components/general/sectionContainer";
import { Tag } from "../components/general/tag";
import { DataContext } from "../lib/contexts";
import {
  calculateDelta,
  calculatePlacement,
  doFetch,
  getPrettyTrackName,
  isDd2Session,
  makeLeaderboard,
  msToLaptime,
  roundTimestamp,
  tagSorter,
  timestampToPrettyDate,
} from "../lib/functions";
import { InfoType, Session } from "../lib/models";
import { ButtonBar } from "../components/general/buttonBar";
import { Button } from "../components/general/button";
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { APIResources, DOMAIN, LF_INFO_TYPE, TRACKS } from "../lib/definitions";
import { NumberPlate } from "../components/numberplate";
import { InfoItem } from "../components/general/infoItem";
import { RadioGroup } from "../components/general/radioGroup";
import * as localForage from "localforage";
import { MdMovie } from "react-icons/md";

export function SessionScreen() {
  const [session, setSession] = useState<Session>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [infoType, setInfoType] = useState<InfoType>("Weight");

  const { id } = useParams<{ id: string }>();
  const { sessions, userInfo, refresh, myStints, getDriverName, setCurrentNavItem } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();

  const placement = useMemo(() => calculatePlacement(session, userInfo.email), [session, userInfo.email]);
  const layout = useMemo(() => TRACKS.find((t) => t.id === session?.track)?.layouts.find((l) => l.id === session?.layout), [session]);

  useEffect(() => {
    if (placement === -1) {
      setInfoType("Weight");
    } else {
      localForage.getItem(LF_INFO_TYPE, (_err, v) => setInfoType((v as InfoType) || "Weight"));
    }
  }, [placement]);

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  useEffect(() => {
    setSession(sessions.find((s) => s._id === id));
  }, [id, sessions]);

  const leaderboard = useMemo(() => makeLeaderboard(session?.stints || []), [session]);

  const myStintId = useMemo(() => myStints.find((s) => s.sessionId === id)?._id, [myStints, id]);

  const multipleSessionsOnTheDay = useMemo(() => {
    const [lowerBound, upperBound] = roundTimestamp(session?.timestamp);

    return (
      sessions.filter(
        (s) => lowerBound <= s.timestamp && s.timestamp <= upperBound && s.track === session?.track && s.layout === session.layout
      ).length > 1
    );
  }, [session, sessions]);

  const showNumberPlates = useMemo(() => session?.stints.reduce((prev, curr) => prev || !!curr.kartNumber, false), [session]);

  function onEntryCellClick(entry: any) {
    history.push(`/stints/${entry.stintId}`);
  }

  const youtubeUrls = useMemo(
    () => (!session ? [] : session.stints.reduce<string[]>((prev, curr) => prev.concat(...curr.youtubeUrls), [])),
    [session]
  );

  const buttons: ReactNode[] = [];

  if (myStintId) {
    buttons.push(
      <Button
        text="Your Stint"
        icon={<ArrowRightOutlined />}
        onClick={() => history.push(`/stints/${myStintId}`)}
        style={{ marginLeft: 8 }}
      />
    );
  } else if (layout?.current) {
    buttons.push(
      <Button
        text="Stint"
        icon={<PlusOutlined />}
        onClick={() =>
          history.push(`/stints/new-stint?sessionid=${id}&back-url=${encodeURIComponent(location.pathname + location.search)}`)
        }
      />
    );
  }

  if (layout?.current && (session?.owner === userInfo.email || userInfo.email === "thisbecasper@gmail.com")) {
    buttons.push(
      <Button
        text="Edit"
        icon={<EditOutlined />}
        onClick={() => history.push(`/tracks/${session?.track}/${session?.layout}/new-session/${session?._id}`)}
        style={{ marginRight: "8px", marginLeft: "8px" }}
      />,
      <Button
        danger
        text="Delete"
        icon={<DeleteOutlined />}
        onClick={() => {
          if (window.confirm("Are you sure you want to delete this session?")) {
            setIsDeleting(true);
            doFetch(
              "DELETE",
              `${APIResources.Sessions}/${id}`,
              () => {
                refresh();
                history.push(`/tracks/${session?.track}/${session?.layout}/sessions`);
              },
              (err) => alert(`An error occured: Deleting session: ${err}`),
              () => setIsDeleting(false)
            );
          }
        }}
        loading={isDeleting}
      />
    );
  }

  return (
    <ScrollableContainer id="session">
      <TrackLogoBanner trackId={session?.track} layout={session?.layout} />
      <Header level={1} title="Session overview" onBackUrl={`/tracks/${session?.track}/${session?.layout}/sessions`} />
      {layout?.current && myStintId && userInfo.email === "thisbecasper@gmail.com" && (
        <ButtonBar style={{ marginBottom: 14 }}>
          <Button
            text="Stint"
            icon={<PlusOutlined />}
            onClick={() =>
              history.push(`/stints/new-stint?sessionid=${id}&back-url=${encodeURIComponent(location.pathname + location.search)}`)
            }
          />
        </ButtonBar>
      )}
      {buttons.length > 0 && <ButtonBar>{buttons}</ButtonBar>}
      <SectionContainer style={{ fontSize: "20px" }}>
        <InfoItem label="Track" value={getPrettyTrackName(session?.track, session?.layout)} />
        <InfoItem label="Date" value={timestampToPrettyDate(session?.timestamp, true)} />
        <InfoItem label="Session owner" value={getDriverName(session?.owner)} />
        <InfoItem
          label="Tags"
          value={
            <div style={{ marginBottom: "10px" }}>
              {session?.tags.sort(tagSorter).map((t) => (
                <Tag key={t} text={t} marginSide="right" />
              ))}
            </div>
          }
        />
        {leaderboard.findIndex((v) => v.driver === userInfo.email) !== -1 && (
          <InfoItem
            label="Placement"
            value={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontSize: "52px", marginRight: "8px" }}>{leaderboard.findIndex((v) => v.driver === userInfo.email) + 1}</div>
                <div style={{ fontSize: "24px", marginTop: "8px" }}>/ {leaderboard.length}</div>
              </div>
            }
          />
        )}
        {multipleSessionsOnTheDay && (
          <div style={{ marginTop: "24px", display: "flex", justifyContent: "center", marginBottom: 36 }}>
            <Button
              text="Event overview"
              onClick={() => history.push(`/event-overview/${session?._id}?back-url=${location.pathname + location.search}`)}
              icon={<ArrowRightOutlined />}
            />
          </div>
        )}
      </SectionContainer>
      <Header level={2} title="Lap times" smallTopMargin={multipleSessionsOnTheDay} />
      {leaderboard.length === 0 ? (
        <div className="placeholder">No times yet...</div>
      ) : (
        <>
          {leaderboard.length > 1 && (
            <RadioGroup
              options={[
                placement !== -1 ? { label: "You", value: "You" } : undefined,
                { label: "Leader", value: "Leader" },
                { label: "Gap", value: "Gap" },
                { label: "Weight", value: "Weight" },
              ]}
              onClick={(v) => {
                setInfoType(v);
                localForage.setItem(LF_INFO_TYPE, v);
              }}
              currentValue={infoType}
              style={{ margin: "0 12px 12px 12px" }}
            />
          )}
          <div
            style={{
              display: "grid",
              gridAutoRows: "min-content",
              gridTemplateColumns: `min-content ${showNumberPlates ? "min-content" : ""} auto min-content min-content min-content`,
              rowGap: "4px",
            }}
          >
            {leaderboard.map((entry, i) => (
              <React.Fragment key={entry.driver}>
                <ListItemCell
                  align="end"
                  space={8}
                  style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                  onClick={() => onEntryCellClick(entry)}
                >
                  {i + 1 + "."}
                </ListItemCell>
                {showNumberPlates && (
                  <ListItemCell space={[4, 12]} onClick={() => onEntryCellClick(entry)}>
                    {
                      <NumberPlate
                        number={entry.kartNumber}
                        style={{
                          color: entry.driver === userInfo.email ? "#009eff" : undefined,
                          borderColor: entry.driver === userInfo.email ? "#009eff" : undefined,
                        }}
                      />
                    }
                  </ListItemCell>
                )}
                <ListItemCell
                  onClick={() => onEntryCellClick(entry)}
                  style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                >
                  {getDriverName(entry.driver)}
                </ListItemCell>
                <ListItemCell space={4} onClick={() => onEntryCellClick(entry)}>
                  <MdMovie
                    style={{
                      fontSize: 18,
                      marginBottom: -4,
                      visibility: entry.youtubeUrls.length > 0 ? undefined : "hidden",
                    }}
                  />
                </ListItemCell>
                <ListItemCell
                  space={4}
                  align="end"
                  onClick={() => onEntryCellClick(entry)}
                  style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                >
                  {msToLaptime([entry.time], isDd2Session(session))}
                </ListItemCell>
                <ListItemCell
                  space={[4, 12]}
                  onClick={() => onEntryCellClick(entry)}
                  align="end"
                  style={{ color: entry.driver === userInfo.email ? "#009eff" : undefined }}
                >
                  {infoType === "Weight"
                    ? entry.weight
                      ? entry.weight + "kg"
                      : "---"
                    : calculateDelta(
                        leaderboard[infoType === "Leader" ? 0 : infoType === "You" ? placement : Math.max(i - 1, 0)].time,
                        entry.time,
                        isDd2Session(session)
                      )}
                </ListItemCell>
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {youtubeUrls.length > 0 && (
        <React.Fragment>
          <Header level={2} title="Onboards" />
          <div style={{ display: "grid", rowGap: 36, padding: 16 }}>
            {youtubeUrls.map((url) => (
              <iframe
                key={url}
                id="ytplayer"
                title="ytplayer"
                //@ts-ignore
                type="text/html"
                width="100%"
                height="240px"
                src={`https://www.youtube.com/embed/${url.split("/").slice(-1)}?origin=http://gokart.${DOMAIN}`}
                frameBorder="0"
                style={{ borderRadius: "24px", boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}
                allowFullScreen
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </ScrollableContainer>
  );
}
