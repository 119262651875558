export interface Track {
  id: string;
  name: string;
  location: string;
  kartTypes: KartType[];
  layouts: Layout[];
}

export interface Layout {
  id: number;
  name: string;
  current: boolean;
  length: number;
  onboardVideo?: { id: string; driver: string; laptime: number };
  fromToYear?: string;
}

export interface TagType {
  text: string;
  color: string;
}

export interface UserInfo {
  email: string;
  firstname: string;
  lastname: string;
  nickname: string;
}

export interface User {
  email: string;
  firstname: string;
  lastname: string;
  fullName: string;
  nickname: string;
}

export interface Credentials extends AWS.CognitoIdentityServiceProvider.AuthenticationResultType {
  ExpirationTimestamp: number;
}

export interface Session {
  _id: string;
  owner: string;
  track: string;
  layout: number;
  timestamp: number;
  tags: string[];
  favorited: boolean;
  stints: Stint[];
}

export interface Stint {
  _id: string;
  sessionId: string;
  driver: string;
  time: number[];
  weight: number;
  kartNumber: number | null;
  notes: string;
  favorited: boolean;
  youtubeUrls: string[];
}

export interface StintExtended extends Stint {
  drivers: string[];
  session: Session;
}

export interface MedalInfo {
  pos: number;
  trackName: string;
  kartType: string;
  weightLimit: number;
  sessionId: string;
  timestamp: number;
}

export enum KartType {
  "9HK" = "9HK",
  "13HK" = "13HK",
  "EL" = "EL",
  "DD2" = "DD2",
}

export interface LaptimeWeightDataPoint {
  weight: number;
  time: number;
}
export interface LaptimeWeightDriverData {
  driver: string;
  dataPoints: LaptimeWeightDataPoint[];
}

export type LaptimeWeightGraphData = {
  weightMin: number;
  weightMax: number;
  timeMin: number;
  timeMax: number;
  yTicks: number[];
  xTicks: number[];
  driverData: LaptimeWeightDriverData[];
};

export interface LeaderboardEntry {
  time: number;
  splits: number[];
  timestamp: number;
  weight: number;
  sessionId: string;
  driver: string;
}

export type Leaderboard = { kartType: KartType; leaderboardEntries: LeaderboardEntry[] };

export type WeightLeaderboard = { weightLimit: number; leaderboardEntries: LeaderboardEntry[] };

export type InfoType = "You" | "Leader" | "Gap" | "Weight";

export type NavItem = "Track" | "Stint" | "User";
