import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { TrackCard } from "../components/trackCard";
import { DataContext } from "../lib/contexts";
import { KartType } from "../lib/models";
import * as localForage from "localforage";
import { calculateTotalLaptime, getSessionKartType } from "../lib/functions";
import { LF_SCROLL_POS_TRACKS, LF_TRACKS_SORTING, TRACKS } from "../lib/definitions";
import { RadioGroup } from "../components/general/radioGroup";
import { useState } from "react";
import { Spinner } from "../components/general/spinner";

export function TrackListScreen() {
  const [sortByTimesDriven, setSortByTimesDriven] = useState(false);

  const history = useHistory();

  const { myStints, isWorking, setCurrentNavItem } = useContext(DataContext);

  useEffect(() => {
    localForage.getItem(LF_TRACKS_SORTING, (_err, val) => setSortByTimesDriven(!!val));
  }, []);

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  const tracksWExtra = useMemo(() => {
    const laptimes: { [k in string]: { [k in number]: { [k in string]: number } } } = {};
    const timesDriven: { [k in string]: number } = {};

    myStints.forEach((s) => {
      const { track, layout } = s.session;

      if (!laptimes[track]) {
        laptimes[track] = {};
      }
      if (!laptimes[track][layout]) {
        laptimes[track][layout] = {};
      }

      const time = calculateTotalLaptime(s.time);
      const kartType = getSessionKartType(s.session);
      laptimes[track][layout][kartType] = Math.min(laptimes[track][layout][kartType] || Number.MAX_SAFE_INTEGER, time);

      if (!timesDriven[track]) {
        timesDriven[track] = 1;
      } else {
        timesDriven[track] += 1;
      }
    });

    return TRACKS.map((t) => ({
      ...t,
      timesDriven: timesDriven[t.id] || 0,
      bestLaptimes: Object.entries(laptimes[t.id] || {}).map(([layout, laptimeData]) => ({
        layout: layout,
        laptimes: Object.entries(laptimeData || {}).map(([kartType, laptime]) => ({
          kartType: kartType as KartType,
          laptime: laptime,
        })),
      })),
    }));
  }, [myStints]);

  const sortedTracks = useMemo(
    () =>
      [...tracksWExtra].sort((a, b) => {
        if (sortByTimesDriven) {
          return b.timesDriven - a.timesDriven;
        } else {
          return a.name.localeCompare(b.name);
        }
      }),
    [sortByTimesDriven, tracksWExtra]
  );

  return (
    <ScrollableContainer id={LF_SCROLL_POS_TRACKS}>
      <RadioGroup
        currentValue={sortByTimesDriven}
        onClick={(v) => {
          setSortByTimesDriven(v);
          localForage.setItem(LF_TRACKS_SORTING, v);
        }}
        options={[
          { label: "Alphabetically", value: false },
          { label: "Times driven", value: true },
        ]}
        style={{ margin: "24px 48px", fontSize: "14px" }}
      />
      <div style={{ padding: "0 24px" }}>
        {isWorking && myStints.length === 0 ? (
          <Spinner style={{ marginTop: "128px", fontSize: "48px" }} />
        ) : (
          sortedTracks.map((t) => (
            <TrackCard
              key={t.id}
              id={t.id}
              name={t.name}
              bestLaptimes={t.bestLaptimes}
              onClick={() => {
                if (t.layouts.length === 1) {
                  history.push(`/tracks/${t.id}/${t.layouts[0].id}`);
                } else {
                  history.push(`/tracks/${t.id}`);
                }
              }}
            />
          ))
        )}
      </div>
    </ScrollableContainer>
  );
}
