import { CSSProperties } from "react";
import { BLUE } from "../../lib/definitions";

interface RadioGroupProps {
  currentValue: any;
  options: ({ label: string; value: any } | undefined)[];
  onClick(value: any): void;
  style?: CSSProperties;
}

export function RadioGroup(props: RadioGroupProps) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${props.options.filter((opt) => !!opt).length},auto)`,
        borderRadius: "4px",
        border: `1px solid ${BLUE}`,
        color: BLUE,
        ...props.style,
      }}
    >
      {props.options.map((o, i) =>
        !o ? null : (
          <div
            key={o.value}
            style={{
              padding: "4px 8px",
              borderRight: i !== props.options.length - 1 ? `1px solid ${BLUE}` : undefined,
              color: props.currentValue === o.value ? "white" : undefined,
              background: props.currentValue === o.value ? BLUE : undefined,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => props.onClick(o.value)}
          >
            {o.label}
          </div>
        )
      )}
    </div>
  );
}
