import { useContext, useMemo } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { DataContext } from "../lib/contexts";
import { LAPTIME_WEIGHT_COLORS } from "../lib/definitions";
import { generateLaptimeWeightData } from "../lib/functions";
import { StintExtended } from "../lib/models";
import { Tag } from "./general/tag";

interface LaptimeWeightGraphProps {
  stints: StintExtended[];
}

export function LaptimeWeightGraph(props: LaptimeWeightGraphProps) {
  const data = useMemo(() => generateLaptimeWeightData(props.stints), [props.stints]);

  const { getDriverName } = useContext(DataContext);

  return (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="weight"
            type="number"
            domain={[data.weightMin, data.weightMax]}
            ticks={data.xTicks}
            tickFormatter={(t) => `${t}kg`}
          />
          <YAxis domain={[data.timeMin, data.timeMax]} ticks={data.yTicks} tickFormatter={(t) => `${t / 1000}s`} />
          {data.driverData.map(({ driver, dataPoints }, i) => (
            <Line
              key={driver}
              type="monotone"
              dataKey="time"
              stroke={LAPTIME_WEIGHT_COLORS[i % LAPTIME_WEIGHT_COLORS.length]}
              data={dataPoints}
              dot={false}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <div style={{ margin: "0 16px", textAlign: "center" }}>
        {data.driverData.map(({ driver }, i) => (
          <Tag
            key={driver}
            text={getDriverName(driver)}
            color={LAPTIME_WEIGHT_COLORS[i % LAPTIME_WEIGHT_COLORS.length]}
            style={{ marginBottom: "4px" }}
          />
        ))}
      </div>
    </div>
  );
}
