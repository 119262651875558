import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "../components/general/button";
import { Header } from "../components/general/header";
import { InfoItem } from "../components/general/infoItem";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { SectionContainer } from "../components/general/sectionContainer";
import { Tag } from "../components/general/tag";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import { APIResources, conditionTags, DD2_DRIVERS, kartTags, raceTags, TRACKS } from "../lib/definitions";
import { doFetch, getDateForInput, getPrettyTrackName, tagsIncludesTypeAndKart } from "../lib/functions";
import { KartType, TagType, Track } from "../lib/models";

export function UpsertSessionScreen() {
  const [track, setTrack] = useState<Track>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isWorking, setIsWorking] = useState(false);
  const [availableKartTags, setAvailableKartTags] = useState<TagType[]>([]);

  const { trackId, layoutId, sessionId } = useParams<{ trackId: string; layoutId: string; sessionId: string }>();
  const { refresh, sessions, userInfo, setIsInputting, setCurrentNavItem } = useContext(DataContext);
  const history = useHistory();

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  useEffect(() => {
    let tags = kartTags.filter((t) => TRACKS.find((t) => t.name === track?.name)?.kartTypes.includes(t.text as KartType));
    if (!DD2_DRIVERS.includes(userInfo.email)) {
      tags = tags.filter((t) => t.text !== "DD2");
    }

    if (tags.length === 1) {
      setSelectedTags((ts) => (ts.find((t) => t === tags[0].text) ? ts : ts.concat(tags[0].text)));
    }

    setAvailableKartTags(tags);
  }, [track, userInfo.email]);

  useEffect(() => {
    if (sessionId) {
      const session = sessions.find((s) => s._id === sessionId);
      if (session) {
        setSelectedDate(new Date(session.timestamp));
        setSelectedTags(session.tags);
      }
    }
  }, [sessionId, sessions]);

  useEffect(() => setTrack(TRACKS.find((t) => t.id === trackId)), [trackId]);

  return (
    <ScrollableContainer>
      <TrackLogoBanner trackId={trackId} layout={layoutId} />
      <Header level={1} title={`${sessionId ? "Update" : "New"} session`} onBackUrl={`/tracks/${trackId}/${layoutId}/sessions`} />
      <SectionContainer>
        <InfoItem label="Track" value={getPrettyTrackName(trackId, layoutId)} />
      </SectionContainer>
      <Header level={2} title="Date" smallTopMargin />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text=""
          icon={
            <input
              autoFocus={!sessionId}
              type="datetime-local"
              value={getDateForInput(selectedDate)}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
              onFocus={() => setIsInputting(true)}
              onBlur={() => setIsInputting(false)}
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                border: "none",
                background: "none",
                textAlign: "center",
                outline: "none",
                color: "#636363",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
              className="unstyled"
            />
          }
        />
      </div>
      <Header level={2} title="Tags" style={{ marginTop: "14px" }} />
      <Header level={3} title="Session type" smallTopMargin />
      <div style={{ margin: "0 16px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", rowGap: "16px", justifyItems: "center" }}>
        {raceTags.map((t) => (
          <Tag
            key={t.text}
            text={t.text}
            width={78}
            onClick={() => {
              if (selectedTags.includes(t.text)) {
                setSelectedTags((ts) => ts.filter((tt) => tt !== t.text));
              } else {
                setSelectedTags((ts) => ts.concat(t.text));
              }
            }}
            outline={!selectedTags.includes(t.text)}
          />
        ))}
      </div>
      {availableKartTags.length > 1 && (
        <>
          <Header level={3} title="Gokart type" />
          <div style={{ margin: "0 16px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", rowGap: "16px", justifyItems: "center" }}>
            {availableKartTags.map((t) => (
              <Tag
                key={t.text}
                text={t.text}
                width={78}
                onClick={() => {
                  if (selectedTags.includes(t.text)) {
                    setSelectedTags((ts) => ts.filter((tt) => tt !== t.text));
                  } else {
                    setSelectedTags((ts) => ts.concat(t.text));
                  }
                }}
                outline={!selectedTags.includes(t.text)}
              />
            ))}
          </div>
        </>
      )}
      <Header level={3} title="Conditions" />
      <div style={{ margin: "0 16px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", rowGap: "16px", justifyItems: "center" }}>
        {conditionTags.map((t) => (
          <Tag
            key={t.text}
            text={t.text}
            width={78}
            onClick={() => {
              if (selectedTags.includes(t.text)) {
                setSelectedTags((ts) => ts.filter((tt) => tt !== t.text));
              } else {
                setSelectedTags((ts) => ts.concat(t.text));
              }
            }}
            outline={!selectedTags.includes(t.text)}
          />
        ))}
      </div>
      <div style={{ display: "flex", marginTop: "72px", justifyContent: "center" }}>
        <Button
          text={`${sessionId ? "Update" : "Create"} session`}
          disabled={!track || !tagsIncludesTypeAndKart(selectedTags)}
          onClick={() => {
            setIsWorking(true);
            doFetch(
              sessionId ? "PUT" : "POST",
              APIResources.Sessions + (sessionId ? `/${sessionId}` : ""),
              (id) => refresh(() => history.push(`/sessions/${sessionId || id}`)),
              (err) => alert(`An error occured: ${sessionId ? "Updating" : "Creating"} session: ${err}`),
              () => setIsWorking(false),
              {
                track: trackId,
                layout: Number.parseInt(layoutId),
                timestamp: selectedDate.getTime(),
                tags: selectedTags,
              }
            );
          }}
          loading={isWorking}
        />
      </div>
    </ScrollableContainer>
  );
}
