import { LoadingOutlined } from "@ant-design/icons";
import React, { CSSProperties } from "react";
import { BLUE } from "../../lib/definitions";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  danger?: boolean;
  style?: CSSProperties;
  loading?: boolean;
  onClick?(): void;
}

export function Button(props: ButtonProps) {
  const color = props.disabled ? "#c4c4c4" : props.danger ? "red" : BLUE;

  return (
    <div
      onClick={() => {
        if (!props.disabled && props.onClick) {
          props.onClick();
        }
      }}
      style={{
        fontSize: "13px",
        borderRadius: "8px",
        border: `1px solid ${color} `,
        padding: "8px 16px",
        color: color,
        display: "flex",
        position: "relative",
        cursor: "pointer",
        ...props.style,
      }}
    >
      <div style={{ visibility: props.loading ? "hidden" : undefined, whiteSpace: "nowrap" }}>{props.text}</div>
      {props.icon && (
        <span style={{ marginLeft: props.text && "8px", visibility: props.loading ? "hidden" : undefined }}>{props.icon}</span>
      )}
      {props.loading && (
        <div
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingOutlined style={{ color: color, fontSize: "20px" }} />
        </div>
      )}
    </div>
  );
}
