import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UserInfo } from "./lib/models";
import { clearCredentials, getUserInfo } from "./lib/credentialsHandler";
import { LoginScreen } from "./screens/loginScreen";
import { Main } from "./components/main";
import { WelcomeScreen } from "./screens/welcomeScreen";
import * as localForage from "localforage";
import { LF_DD2_WEIGHT, LF_SCROLL_POS_TRACKS, LF_WEIGHT } from "./lib/definitions";
import { checkVersionRefresh } from "./lib/functions";

function App() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isFirstLoginTry, setIsFirstLoginTry] = useState(true);

  const history = useHistory();

  useEffect(() => {
    updateUserInfo();

    localForage.getItem(LF_WEIGHT, (_err, val) => val === null && localForage.setItem(LF_WEIGHT, 80));
    localForage.getItem(LF_DD2_WEIGHT, (_err, val) => val === null && localForage.setItem(LF_DD2_WEIGHT, 175));
  }, []);

  async function updateUserInfo() {
    setIsGettingUserInfo(true);
    try {
      setUserInfo(await getUserInfo());
      await localForage.setItem(LF_SCROLL_POS_TRACKS, 0);
    } catch {
      checkVersionRefresh();
    }
    setIsGettingUserInfo(false);
  }

  return (
    <div style={{ height: "100%" }}>
      {isGettingUserInfo && isFirstLoginTry && !isWorking ? (
        <WelcomeScreen />
      ) : (
        <>
          {!userInfo ? (
            <LoginScreen onLogin={updateUserInfo} isWorking={isWorking} setIsWorking={setIsWorking} />
          ) : (
            <Main
              userInfo={userInfo}
              onLogout={async () => {
                setIsFirstLoginTry(false);
                await clearCredentials();
                setUserInfo(undefined);
                history.push("/login");
              }}
            />
          )}
          <div style={{ height: "200px" }} />
        </>
      )}
    </div>
  );
}

export default App;
