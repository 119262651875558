import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useContext, useMemo } from "react";
import { MdMovie } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../components/general/button";
import { ButtonBar } from "../components/general/buttonBar";
import { Header } from "../components/general/header";
import { ListItemCell } from "../components/general/listItemCell";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { Tag } from "../components/general/tag";
import { TimeStampAsDate } from "../components/timestampAsDate";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import { LF_SCROLL_POS_SESSIONS, TRACKS } from "../lib/definitions";
import { makeLeaderboard, removeBorderOnMiddleItem, splitItemsIntoYearAndDay, tagSorter } from "../lib/functions";
import { Session, Stint } from "../lib/models";

export function TrackSessionsScreen() {
  const { sessions, userInfo, setCurrentNavItem } = useContext(DataContext);
  const { trackId, layoutId } = useParams<{ trackId: string; layoutId: string }>();
  const history = useHistory();

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  const layout = useMemo(() => TRACKS.find((t) => t.id === trackId)?.layouts.find((l) => l.id + "" === layoutId), [trackId, layoutId]);

  const sessionsSplitIntoYear = useMemo(
    () =>
      splitItemsIntoYearAndDay(sessions.filter((s) => s.track === trackId && s.layout + "" === layoutId)).map(({ year, items }) => ({
        year,
        items: items.map((sessions) =>
          sessions.map((s: any) => {
            const placement = makeLeaderboard(s.stints).findIndex((entry) => entry.driver === userInfo.email) + 1;
            return { ...s, placement: placement === 0 ? s.stints.length : placement + "/" + s.stints.length };
          })
        ),
      })),
    [sessions, trackId, layoutId, userInfo.email]
  );

  function onSessionCellClick(session: Session) {
    history.push(`/sessions/${session._id}`);
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_SESSIONS}>
      <TrackLogoBanner trackId={trackId} layout={layoutId} />
      <Header level={1} title="Sessions" onBackUrl={`/tracks/${trackId}/${layoutId}`} />
      {layout?.current && (
        <ButtonBar style={{ marginBottom: "30px" }}>
          <Button text="New session" icon={<PlusOutlined />} onClick={() => history.push(`/tracks/${trackId}/${layoutId}/new-session`)} />
        </ButtonBar>
      )}
      {sessionsSplitIntoYear.length === 0 ? (
        <div style={{ textAlign: "center" }}>No sessions have been created...</div>
      ) : (
        sessionsSplitIntoYear.map((ys) => (
          <div key={ys.year}>
            <div className="list-header">{ys.year}</div>
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content min-content",
              }}
            >
              {ys.items.map((sessionsOnADay) =>
                sessionsOnADay.map((s: any, index: number) => (
                  <React.Fragment key={s._id}>
                    {/* <ListItemCell
                      space={[10, 0]}
                      align="center"
                      style={{ ...removeBorderOnMiddleItem(sessionsOnADay, index), color: s.favorited ? "#d0af00" : undefined }}
                      onClick={() => onSessionCellClick(s)}
                    >
                      {s.stints.findIndex((s: Stint) => s.driver === userInfo.email) !== -1 && (
                        <div style={{ background: BLUE, height: "6px", width: "6px", borderRadius: "4px" }} />
                      )}
                    </ListItemCell> */}
                    <ListItemCell
                      space={[12, 8]}
                      align="center"
                      style={{ ...removeBorderOnMiddleItem(sessionsOnADay, index) }}
                      innerStyle={{ paddingRight: "1px" }}
                      onClick={() => onSessionCellClick(s)}
                    >
                      <TimeStampAsDate timestamp={s.timestamp} style={{ fontSize: "12px" }} />
                    </ListItemCell>
                    <ListItemCell style={{ ...removeBorderOnMiddleItem(sessionsOnADay, index) }} onClick={() => onSessionCellClick(s)}>
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                        {s.tags
                          .sort((a: string, b: string) => tagSorter(a, b, true))
                          .map((t: any) => (
                            <Tag key={t} text={t} style={{ fontSize: "12px", padding: "3px 6px" }} />
                          ))}
                      </div>
                    </ListItemCell>
                    <ListItemCell
                      space={[0, 2]}
                      align="end"
                      style={{
                        ...removeBorderOnMiddleItem(sessionsOnADay, index),
                      }}
                      onClick={() => onSessionCellClick(s)}
                    >
                      <MdMovie
                        style={{
                          fontSize: 18,
                          marginBottom: -4,
                          visibility: s.stints.reduce((prev: boolean, curr: Stint) => prev || curr.youtubeUrls.length > 0, false)
                            ? undefined
                            : "hidden",
                        }}
                      />
                    </ListItemCell>
                    <ListItemCell
                      space={[8, 16]}
                      style={{ ...removeBorderOnMiddleItem(sessionsOnADay, index) }}
                      onClick={() => onSessionCellClick(s)}
                      align="end"
                    >
                      {s.placement}
                    </ListItemCell>
                    {index === sessionsOnADay.length - 1 && <div style={{ gridColumn: "span 4", height: "8px" }} />}
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        ))
      )}
    </ScrollableContainer>
  );
}
