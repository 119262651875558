import { useHistory } from "react-router";

interface TrackLogoBannerProps {
  trackId: string | undefined;
  layout: number | string | undefined;
}

export function TrackLogoBanner(props: TrackLogoBannerProps) {
  const history = useHistory();

  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: "24px 0 8px", cursor: "pointer" }}
      onClick={() => history.push(`/tracks/${props.trackId}/${props.layout}`)}
    >
      {props.trackId && (
        <img
          src={`/assets/${props.trackId}-logo.png`}
          alt=""
          style={{
            maxWidth: "80%",
            alignSelf: "flex-start",
            maxHeight: "92px",
            borderRadius: "8px",
            boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          }}
        />
      )}
    </div>
  );
}
