import { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { Header } from "../components/general/header";
import { StintExtended } from "../lib/models";
import { DataContext } from "../lib/contexts";
import { doFetch, getPrettyTrackName, isDd2Session, msToLaptime, tagSorter, timestampToPrettyDate } from "../lib/functions";
import { Button } from "../components/general/button";
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { ButtonBar } from "../components/general/buttonBar";
import { DetailsGrid } from "../components/general/detailsGrid";
import { SectionContainer } from "../components/general/sectionContainer";
import { Tag } from "../components/general/tag";
import { APIResources, DOMAIN, LF_SCROLL_POS_STINT } from "../lib/definitions";
import { NumberPlate } from "../components/numberplate";
import { InfoItem } from "../components/general/infoItem";

export function StintScreen() {
  const [stint, setStint] = useState<StintExtended>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [time, setTime] = useState(0);

  const { id } = useParams<{ id: string }>();
  const { allStints, refresh, getDriverName, userInfo, setCurrentNavItem } = useContext(DataContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => setCurrentNavItem("Stint"), [setCurrentNavItem]);

  useEffect(() => {
    setStint(allStints.find((s) => s._id === id));
  }, [id, allStints]);

  function updateFavorited() {
    if (!stint) {
      return;
    }
    setStint({ ...stint, favorited: !stint.favorited });
    doFetch(
      "PUT",
      APIResources.Stints + `/${id}`,
      refresh,
      (err) => alert(`An error occured: ${stint?.favorited ? "Unfavoriting" : "Favoriting"} stint: ${err}`),
      undefined,
      { favorited: !stint?.favorited }
    );
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_STINT}>
      <TrackLogoBanner trackId={stint?.session.track} layout={stint?.session.layout} />
      <Header
        level={1}
        title="Stint overview"
        onBackUrl={`/sessions/${stint?.sessionId}`}
        extra={
          stint?.driver === userInfo.email &&
          (stint?.favorited ? (
            <StarFilled style={{ fontSize: "24px", color: "gold", outline: "none" }} onClick={updateFavorited} />
          ) : (
            <StarOutlined style={{ fontSize: "24px", outline: "none" }} onClick={updateFavorited} />
          ))
        }
      />
      <ButtonBar>
        <Button
          text="Session"
          icon={<ArrowRightOutlined />}
          onClick={() => history.push(`/sessions/${stint?.sessionId}?back-url=${encodeURIComponent(location.pathname + location.search)}`)}
          style={{ marginRight: "8px" }}
        />
        {(stint?.driver === userInfo.email || userInfo.email === "thisbecasper@gmail.com") && (
          <>
            <Button
              text="Edit"
              icon={<EditOutlined />}
              onClick={() => history.push(`/stints/new-stint/${id}`)}
              style={{ marginRight: "8px" }}
            />
            <Button
              danger
              text="Delete"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this stint?")) {
                  setIsDeleting(true);
                  doFetch(
                    "DELETE",
                    `${APIResources.Stints}/${id}`,
                    () => {
                      refresh();
                      history.goBack();
                    },
                    (err) => alert(`An error occured: Deleting stint: ${err}`),
                    () => setIsDeleting(false)
                  );
                }
              }}
              loading={isDeleting}
            />
          </>
        )}
      </ButtonBar>
      <SectionContainer>
        <InfoItem label="Track" value={getPrettyTrackName(stint?.session.track, stint?.session.layout)} />
        <InfoItem
          label="Tags"
          value={
            <div style={{ marginBottom: "10px" }}>
              {stint?.session.tags.sort(tagSorter).map((t) => (
                <Tag key={t} text={t} marginSide="right" />
              ))}
            </div>
          }
        />
        <InfoItem label="Date & Time" value={timestampToPrettyDate(stint?.session.timestamp, true)} />
        <InfoItem label="Driver" value={getDriverName(stint?.driver)} />
        <InfoItem label="Laptime" value={msToLaptime(stint?.time || [], isDd2Session(stint?.session))} />
        <InfoItem label="Weight" value={stint?.weight ? `${stint?.weight} kg` : "Not provided"} />
        <InfoItem
          label="Kart number"
          value={
            stint?.kartNumber ? (
              <div style={{ marginBottom: "10px" }}>
                <NumberPlate number={stint?.kartNumber} big />
              </div>
            ) : (
              "Not provided"
            )
          }
        />
        <InfoItem label="Notes" value={stint?.notes || "No notes..."} />
      </SectionContainer>
      {stint && stint.time.length > 1 && (
        <>
          <Header level={2} title="Sector times" />
          <SectionContainer>
            <DetailsGrid>
              {stint?.time.map((t, i) => (
                <div key={i} style={{ gridColumn: "span 2", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "20px" }}>{`Sector ${i + 1}:`}</div>
                  <div style={{ fontSize: "26px", textAlign: "end", fontWeight: "unset" }} className="laptime">
                    {msToLaptime([t], isDd2Session(stint.session))}
                  </div>
                </div>
              ))}
            </DetailsGrid>
          </SectionContainer>
        </>
      )}
      {stint && (
        <div style={{ display: "grid", rowGap: 36, padding: 16 }}>
          {stint.youtubeUrls.map((url) => (
            <>
              <iframe
                key={url}
                id="ytplayer"
                title="ytplayer"
                //@ts-ignore
                type="text/html"
                width="100%"
                height="240px"
                src={`https://www.youtube.com/embed/${url.split("/").slice(-1)}?origin=http://gokart.${DOMAIN}&t=${time}`}
                frameBorder="0"
                style={{ borderRadius: "24px", boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}
                allowFullScreen
              />
              <button onClick={() => setTime(60)}>jep</button>
            </>
          ))}
        </div>
      )}
    </ScrollableContainer>
  );
}
