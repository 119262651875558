import { KartType, TagType, Track } from "./models";

export const DOMAIN = "ckal.dk";

export const API_ENDPOINT = `https://api.${DOMAIN}/gokart/`;
export const BACKEND_VERSION_URL = `https://api.${DOMAIN}/version/gokart`;

export const BLUE = "#4bb7f9";

export const LF_CREDENTIALS = "gokart_creds";
export const LF_INFO_TYPE = "gokart_infoType";
export const LF_TRACKS_SORTING = "gokart_trackSorting";
export const LF_STINTS_SHOW_LAPTIME = "gokart_myStintsShowLaptime";
export const LF_WEIGHT = "gokart_weight";
export const LF_DD2_WEIGHT = "gokart_dd2weight";
export const LF_DD2_KART_NUMBER = "gokart_dd2kartNumber";
export const LF_SHOW_WEIGHT = "gokart_showWeight";
export const LF_REFRESH_TARGET = "gokart_refreshTarget";
export const LF_REFRESH_TARGET_DENY = "gokart_refreshTargetDeny";
export const LF_CHECK_NAME = "gokart_checkName";

export const LF_RESTORE_SCROLL = "gokart_scrollRestore";
export const LF_SCROLL_POS_TRACKS = "gokart_scrollPosTracks";
export const LF_SCROLL_POS_TRACK = "gokart_scrollPosTrack";
export const LF_SCROLL_POS_STINTS = "gokart_scrollPosStints";
export const LF_SCROLL_POS_TRACKSTINTS = "gokart_scrollPosTrackStints";
export const LF_SCROLL_POS_SESSIONS = "gokart_scrollPosSessions";
export const LF_SCROLL_POS_EVENTOVERVIEW = "gokart_scrollPosEventOverview";
export const LF_SCROLL_POS_USER = "gokart_scrollPosUser";
export const LF_SCROLL_POS_MEDALLIST = "gokart_scrollMedalList";
export const LF_SCROLL_POS_LEADERBOARDS = "gokart_scrollLeaderboards";
export const LF_SCROLL_POS_STINT = "gokart_scrollStint";
export const LF_SCROLL_POS_ONBOARDS = "gokart_scrollOnboards";

export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

const preTRACKS: Track[] = [
  {
    id: "auning",
    name: "Auning",
    kartTypes: [KartType["9HK"]],
    location: "Drammelstrupvej 1, 8961 Allingåbro",
    layouts: [{ id: 1, name: "Default", current: true, length: 500 }],
  },
  {
    id: "bowlnfunodense",
    name: "Bowl'n'Fun Odense",
    kartTypes: [KartType["9HK"]],
    location: "Grønløkken 3, 5000 Odense",
    layouts: [{ id: 1, name: "Default", current: true, length: 0 }],
  },
  {
    id: "bowlnfunesbjerg",
    name: "Bowl'n'Fun Esbjerg",
    kartTypes: [KartType["9HK"]],
    location: "Glarmestervej 6, 12, 6710 Esbjerg",
    layouts: [{ id: 1, name: "Default", current: true, length: 0 }],
  },
  {
    id: "gokartcenterfyn",
    name: "Gokartcenter Fyn",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Kærbygårdevej 25, 5220 Odense",
    layouts: [{ id: 1, name: "Default", current: true, length: 750 }],
  },
  {
    id: "grindsted",
    name: "Grindsted",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Vesterhedevej 7, 7200 Grindsted",
    layouts: [{ id: 1, name: "Default", current: true, length: 830 }],
  },
  {
    id: "holbæk",
    name: "Holbæk",
    kartTypes: [KartType["9HK"]],
    location: "Omfartsvejen 27, 4300 Holbæk",
    layouts: [
      { id: 1, name: "Bane 1", current: true, length: 570, onboardVideo: { id: "sHu-b9RknZI", driver: "Casper Laursen", laptime: 64817 } },
      { id: 2, name: "Bane 2", current: true, length: 550, onboardVideo: { id: "glGG9XgknqQ", driver: "Casper Laursen", laptime: 46788 } },
      { id: 3, name: "Bane 1 + 2", current: true, length: 1100 },
    ],
  },
  {
    id: "ikast",
    name: "Ikast",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Ringstrupvej 14, 7430 Ikast",
    layouts: [{ id: 1, name: "Default", current: true, length: 956 }],
  },
  {
    id: "korsoer",
    name: "Korsør",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Ørnumvej 20, 4220 Korsør",
    layouts: [{ id: 1, name: "Default", current: true, length: 641 }],
  },
  {
    id: "loekken",
    name: "Løkken",
    kartTypes: [KartType["9HK"]],
    location: "Actionvej 1, 9480 Løkken",
    layouts: [{ id: 1, name: "Default", current: true, length: 1100 }],
  },
  {
    id: "mou",
    name: "Mou",
    kartTypes: [KartType["9HK"]],
    location: "Hedevej 6, 9280 Storvorde",
    layouts: [{ id: 1, name: "Default", current: true, length: 650 }],
  },
  {
    id: "odensegokarthal",
    name: "Odense Gokarthal",
    kartTypes: [KartType["9HK"]],
    location: "Cikorievej 32, 5220 Odense",
    layouts: [{ id: 1, name: "Default", current: true, length: 260 }],
  },
  {
    id: "powerracing",
    name: "PowerRacing",
    kartTypes: [KartType["EL"]],
    location: "Lyskær 7, 2730 Herlev",
    layouts: [
      { id: 1, name: "Default", current: true, length: 300, onboardVideo: { id: "nXOy_Od5CvI", driver: "Casper Laursen", laptime: 30580 } },
    ],
  },
  {
    id: "racehallaarhus",
    name: "RaceHall Aarhus",
    kartTypes: [KartType["9HK"], KartType["13HK"]],
    location: "Hasselager Centervej 30, 8260 Viby J",
    layouts: [
      { id: 1, name: "Default", current: true, length: 1000, onboardVideo: { id: "xBOU_KgmHdQ", driver: "Rasmus Larsen", laptime: 57344 } },
    ],
  },
  {
    id: "racehallcph",
    name: "RaceHall CPH",
    kartTypes: [KartType["9HK"]],
    location: "Bondehøjvej 20, 2630 Taastrup",
    layouts: [
      {
        id: 1,
        name: "Default",
        current: true,
        length: 1250,
        onboardVideo: { id: "9ppiLkuPpV8", driver: "Casper Laursen", laptime: 78688 },
      },
    ],
  },
  {
    id: "racesyd",
    name: "RaceSyd",
    kartTypes: [KartType["9HK"]],
    location: "Nordre Ringvej 9, 7000 Fredericia",
    layouts: [{ id: 1, name: "Default", current: true, length: 310 }],
  },
  {
    id: "roskilde",
    name: "Roskilde",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Abildgårdsvej 1, 4000 Roskilde",
    layouts: [
      {
        id: 1,
        name: "Default",
        current: true,
        length: 1250,
        onboardVideo: { id: "R-zKJrvyVuM", driver: "Casper Laursen", laptime: 68950 },
      },
    ],
  },
  {
    id: "skærbæk",
    name: "Skærbæk",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Astrupvej 40, 6780 Skærbæk",
    layouts: [{ id: 1, name: "Default", current: true, length: 880 }],
  },
  {
    id: "svebølle",
    name: "Svebølle",
    kartTypes: [KartType["9HK"]],
    location: "Frederiksholm 20, 4470 Svebølle",
    layouts: [{ id: 1, name: "Default", current: true, length: 420 }],
  },
  {
    id: "sønderborg",
    name: "Sønderborg",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Pilehavevej 10, 6440 Augustenborg",
    layouts: [
      { id: 1, name: "Default", current: true, length: 765, onboardVideo: { id: "Q4G42r9iL1s", driver: "Casper Laursen", laptime: 47873 } },
    ],
  },
  {
    id: "thy",
    name: "Thy",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Lufthavnsvej 9, 7730 Hanstholm",
    layouts: [{ id: 1, name: "Default", current: true, length: 1170 }],
  },
  {
    id: "vandel",
    name: "Vandel",
    kartTypes: [KartType["13HK"]],
    location: "Tårnvej 8, 7184 Vandel",
    layouts: [
      {
        id: 1,
        name: "Normal (pre 2024)",
        current: false,
        length: 900,
        onboardVideo: { id: "cA9_YJGmGoE", driver: "Casper Laursen", laptime: 53503 },
      },
      { id: 2, name: "Normal", current: true, length: 900, onboardVideo: { id: "cA9_YJGmGoE", driver: "Casper Laursen", laptime: 53503 } },
      { id: 3, name: "Normal reverse", current: true, length: 900 },
      { id: 4, name: "Alternative", current: true, length: 900 },
      { id: 5, name: "Alternative reverse", current: true, length: 900 },
    ],
  },
  {
    id: "viborg",
    name: "Viborg",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Havrisvej 10, 8830 Tjele",
    layouts: [
      { id: 1, name: "Default", current: true, length: 460, onboardVideo: { id: "RQBihGEU5Kw", driver: "Casper Laursen", laptime: 40898 } },
    ],
  },
  {
    id: "vojens",
    name: "Vojens",
    kartTypes: [KartType["9HK"], KartType["DD2"]],
    location: "Tingvejen 27, 6500 Vojens",
    layouts: [
      { id: 1, name: "Bane 1", current: true, length: 590 },
      { id: 2, name: "Bane 2", current: true, length: 700 },
      { id: 3, name: "Bane 1 + 2", current: true, length: 1270 },
    ],
  },
];

export const TRACKS: Track[] = preTRACKS.sort((a, b) => a.name.localeCompare(b.name)) as Track[];

export enum APIResources {
  Sessions = "sessions",
  Stints = "stints",
  MyStints = "my-stints",
  UncoupledSessions = "uncoupled-sessions",
  RegisterName = "register-name",
}

export const DD2_DRIVERS = ["thisbecasper@gmail.com", "doctorherbstein@gmail.com", "simfogh@gmail.com"];

export const raceTags: TagType[] = [
  { text: "Practice", color: "#87d068" },
  { text: "Qualify", color: "#9b68d0" },
  { text: "Race", color: "#ff9342" },
];

export const kartTags: TagType[] = [
  { text: KartType["9HK"], color: "#00a4b7" },
  { text: KartType["13HK"], color: "#de3e00" },
  { text: KartType["EL"], color: "#38e81a" },
  { text: KartType["DD2"], color: "#e200e2" },
];

export const conditionTags: TagType[] = [
  { text: "Balanced", color: "#ff5252" },
  { text: "BOP", color: "#a0a0a0" },
  { text: "Wet", color: BLUE },
];

export const LAPTIME_WEIGHT_COLORS = [
  "red",
  "#007cff",
  "#38c333",
  "orange",
  "purple",
  "#a05252",
  "gray",
  "black",
  "pink",
  "chocolate",
  "darkgreen",
];

export const WEIGHT_CLASSES = [70, 80, 90];
