import React, { useContext } from "react";
import { MdMovie } from "react-icons/md";
import { DataContext } from "../lib/contexts";
import { tagSorter } from "../lib/functions";
import { StintExtended } from "../lib/models";
import { Tag } from "./general/tag";
import { TimeStampAsDate } from "./timestampAsDate";

interface OnboardsCardContentProps {
  trackStintsWithOnboards: StintExtended[];
}

export function OnboardsCardContent(props: OnboardsCardContentProps) {
  const { getDriverName } = useContext(DataContext);

  return props.trackStintsWithOnboards.length === 0 ? (
    <div style={{ fontSize: 14, textAlign: "center", color: "#e9e9e9" }}>No onboards have been recorded</div>
  ) : (
    <div
      style={{ maxHeight: "185px", overflow: "hidden", marginBottom: props.trackStintsWithOnboards.length >= 8 ? "-16px" : undefined }}
      className={props.trackStintsWithOnboards.length >= 8 ? "fade" : undefined}
    >
      <div
        style={{
          display: "grid",
          gridAutoRows: "min-content",
          gridTemplateColumns: "min-content min-content auto min-content min-content",
          alignItems: "center",
          columnGap: "8px",
          rowGap: "4px",
          fontSize: "15px",
        }}
      >
        {props.trackStintsWithOnboards.map((stint) => (
          <React.Fragment key={stint._id}>
            <TimeStampAsDate timestamp={stint.session.timestamp} style={{ whiteSpace: "nowrap", marginRight: 4 }} withYear excludeTime />
            <div>
              <MdMovie
                style={{
                  fontSize: 20,
                  marginBottom: -4,
                }}
              />
            </div>
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{getDriverName(stint.driver)}</div>
            <div style={{ overflow: "hidden", whiteSpace: "nowrap", display: "flex", justifyContent: "flex-end" }}>
              {stint.session.tags
                .sort((a: string, b: string) => tagSorter(a, b))
                .map((t: any) => (
                  <Tag key={t} text={t} noText />
                ))}
            </div>
            <div style={{ whiteSpace: "nowrap", marginLeft: 4 }}>{stint.session.stints.length}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
