import { CSSProperties, useContext } from "react";
import { DataContext } from "../../lib/contexts";

interface NumberInputProps {
  value: string;
  style?: CSSProperties;
  kartNumber?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  onBlur?(): void;
  onChange(value: string): void;
}

export function NumberInput(props: NumberInputProps) {
  const { setIsInputting } = useContext(DataContext);

  return (
    <input
      autoFocus={props.autoFocus}
      value={props.value}
      type="tel"
      onFocus={() => setIsInputting(true)}
      onBlur={() => {
        setIsInputting(false);
        if (props.onBlur) {
          props.onBlur();
        }
      }}
      placeholder={props.placeholder}
      style={{ textAlign: "end", ...props.style }}
      onChange={(e) =>
        (e.target.value === "" || (props.kartNumber ? /^[1-9][0-9]?$/ : /^[1-9][0-9]?[0-9]?$/).test(e.target.value)) &&
        props.onChange(e.target.value)
      }
      className="input"
    />
  );
}
