import { useState } from "react";
import { Button } from "../components/general/button";
import { APIResources } from "../lib/definitions";
import { doFetch } from "../lib/functions";

interface FirstTimeSetupScreenProps {
  info: { [k in string]: number };
  onClose(shouldRefresh?: boolean): void;
}

export function FirstTimeSetupScreen(props: FirstTimeSetupScreenProps) {
  const [name, setName] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  return (
    <div
      style={{ width: "100vw", height: "100vh", position: "absolute", background: "rgba(1,0,0,0.5)", zIndex: 10000 }}
      className="first-time-screen"
    >
      <div
        style={{
          margin: 24,
          background: "white",
          position: "absolute",
          borderRadius: 16,
          padding: 24,
          top: "10%",
        }}
      >
        <div style={{ fontSize: 24, textAlign: "center", marginBottom: 24 }}>First time setup</div>
        <div style={{ textAlign: "center", marginBottom: 8, color: "#757575" }}>
          You are new to the Gokart app, but there's a chance that stints have already been registered for you.
        </div>
        <div style={{ marginBottom: 24, textAlign: "center", color: "#757575" }}>
          Please select your name in the list below if it exists.
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <select placeholder="Select your name..." style={{ marginTop: "12px" }} onChange={(e) => setName(e.target.value)}>
            <option value="">I'm not in the list</option>
            {Object.entries(props.info)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([name, stints]) => (
                <option key={name} value={name}>
                  {name + `, ${stints} stint${stints > 1 ? "s" : ""}`}
                </option>
              ))}
          </select>
        </div>
        <div style={{ marginTop: 48, display: "flex", justifyContent: "center" }}>
          <Button
            text="Continue"
            loading={isWorking}
            onClick={() => {
              if (!name) {
                props.onClose();
              } else {
                setIsWorking(true);
                doFetch(
                  "POST",
                  APIResources.RegisterName,
                  () => props.onClose(true),
                  () => alert("An error occured"),
                  () => setIsWorking(false),
                  { name: name }
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
