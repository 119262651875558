import { CSSProperties } from "react";
import { useLocation } from "react-router";
import { CredentialsError, getAccessToken } from "./credentialsHandler";
import {
  API_ENDPOINT,
  BLUE,
  conditionTags,
  DOMAIN,
  kartTags,
  LF_REFRESH_TARGET,
  LF_REFRESH_TARGET_DENY,
  raceTags,
  TRACKS,
  WEIGHT_CLASSES,
} from "./definitions";
import { LaptimeWeightGraphData, StintExtended, Session, KartType, LeaderboardEntry, MedalInfo, Stint } from "./models";
import * as localForage from "localforage";

export function getDateForInput(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1 + "").padStart(2, "0");
  const day = (date.getDate() + "").padStart(2, "0");
  const time = date.toTimeString().slice(0, 5);

  return `${year}-${month}-${day}T${time}`;
}

export function inputToLapTimeString(input: string, onlyHundreths?: boolean) {
  const numberInput = input.replaceAll(":", "");

  if (numberInput.length < (onlyHundreths ? 3 : 4)) {
    return numberInput;
  } else if (numberInput.length < (onlyHundreths ? 5 : 6)) {
    return `${numberInput.slice(0, numberInput.length - (onlyHundreths ? 2 : 3))}:${numberInput.slice(onlyHundreths ? -2 : -3)}`;
  } else {
    return `${numberInput.slice(0, 1)}:${numberInput.slice(1, 3)}:${numberInput.slice(onlyHundreths ? -2 : -3)}`;
  }
}

export function tagToColor(tag: string) {
  const foundTag =
    raceTags.find((t) => t.text === tag) || kartTags.find((t) => t.text === tag) || conditionTags.find((t) => t.text === tag);

  return foundTag?.color || BLUE;
}

export async function doFetch(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  onOK: (json: any) => void,
  onNotOK: (json: any) => void,
  finallyCallback?: () => void,
  body?: any,
  customUrl?: boolean,
  noToken?: boolean
) {
  try {
    const response = await fetch(`${customUrl ? path : API_ENDPOINT + path}`, {
      headers: await getHeaders(noToken),
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined,
    });
    if (response.ok) {
      try {
        onOK(await response.json());
      } catch {
        onOK(`${response.status} ${response.statusText}`);
      }
    } else {
      try {
        onNotOK(await response.json());
      } catch (error) {
        onNotOK(response.statusText);
      }
    }
  } catch (error) {
    console.log(error);

    if (error instanceof CredentialsError) {
      onNotOK(error.message);
    } else {
      onNotOK("An error occured");
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export async function getHeaders(noToken?: boolean) {
  const headers = new Headers();

  headers.append("Content-Type", "application/json");
  if (!noToken) {
    headers.append("Authorization", await getAccessToken());
  }

  return headers;
}

export function splitItemsIntoYearAndDay(items: any[]) {
  function splitIntoDay(items: any[]) {
    if (items.length === 0) {
      return [];
    }

    const result: any[][] = [];
    let currentDayItems: any[] = [];
    let currentDay = items[0].timestamp || items[0].session.timestamp;
    let currentTrack = items[0].track || items[0].session.track;

    items.forEach((i) => {
      if (currentTrack === i.track && isSameDay(i.timestamp || i.session.timestamp, currentDay)) {
        currentDayItems.push(i);
      } else {
        result.push(currentDayItems);
        currentDayItems = [i];
        currentDay = i.timestamp || i.session.timestamp;
        currentTrack = i.track || i.session.track;
      }
    });

    result.push(currentDayItems);

    return result;
  }

  const preResult: { [k in string]: any[] } = {};

  items.forEach((i) => {
    const year = new Date(i.timestamp || i.session.timestamp).getFullYear();
    preResult[year] = preResult[year] ? preResult[year].concat(i) : [i];
  });

  const result: { year: string; items: any[] }[] = [];

  Object.keys(preResult)
    .sort()
    .reverse()
    .forEach((year) =>
      result.push({
        year: year,
        items: splitIntoDay(preResult[year].sort((a, b) => (b.timestamp || b.session.timestamp) - (a.timestamp || a.session.timestamp))),
      })
    );

  return result;
}

export function toDateAndTime(timestamp: number | undefined, withYear?: boolean, excludeTime?: boolean, onlyYear?: boolean) {
  if (!timestamp) {
    return "No date";
  }

  const date = new Date(timestamp);
  const day = `${date.getDate()}/${date.getMonth() + 1}`;
  const time = `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
  const year = date.getFullYear().toString().slice(2);

  if (onlyYear) {
    return date.getFullYear().toString();
  }

  return `${day}${withYear ? `/${year}` : ""}${excludeTime ? "" : " " + time}`;
}

export function timestampToYear(timestamp: number | undefined) {
  if (!timestamp) {
    return "No date";
  }

  return new Date(timestamp).getFullYear().toString();
}

export function timestampToTime(timestamp: number | undefined) {
  if (!timestamp) {
    return "No time";
  }

  const date = new Date(timestamp);
  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
}

export function timestampToPrettyDate(timestamp: number | undefined, withYear?: boolean, withoutTime?: boolean) {
  if (!timestamp) {
    return "No time";
  }

  const date = new Date(timestamp);

  return (
    getMonthName(date.getMonth()) +
    " " +
    placementToText(date.getDate()) +
    (withYear ? ` ${date.getFullYear()}` : "") +
    (withoutTime ? "" : " " + timestampToTime(timestamp))
  );
}

function getMonthName(month: number) {
  return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][month];
}

export function timeInputIsValid(input: string, onlyHundreths?: boolean) {
  const lastCharWasNumber = !Number.isNaN(Number.parseInt(input[input.length - 1]));
  const isEmpty = input.length === 0;
  const isNotTooLong = input.replaceAll(":", "").length < (onlyHundreths ? 6 : 7);

  return (lastCharWasNumber || isEmpty) && isNotTooLong;
}

export function getPrettyTrackName(trackId: string | undefined, layoutNo: number | string | undefined) {
  const track = TRACKS.find((t) => t.id === trackId);

  if (!track) {
    return "No track name";
  }

  let addOn = "";

  if (layoutNo && track.layouts.length > 1) {
    const layout = track.layouts.find((l) => l.id + "" === layoutNo + "");
    addOn += ` - ${layout?.name}`;
  }

  return TRACKS.find((t) => t.id === trackId)?.name + addOn || "No track name";
}

export function msToLaptime(time: number[], onlyHundreths?: boolean) {
  const ms = calculateTotalLaptime(time);
  const mins = Math.floor(ms / 60000);
  const secs = Math.floor((ms % 60000) / 1000);
  const thousands = ms % 1000;

  if (isNaN(ms) || isNaN(mins) || isNaN(secs) || isNaN(thousands)) {
    return "";
  }

  return `${mins ? mins + ":" : ""}${secs.toString().padStart(2, "0")}:${(onlyHundreths ? Math.floor(thousands / 10) : thousands)
    .toString()
    .padStart(onlyHundreths ? 2 : 3, "0")}`;
}

export function laptimeToMs(laptime: string, onlyHundreths?: boolean) {
  if (!laptime) {
    return 0;
  }

  const elems = laptime.split(":").map((v) => Number.parseInt(v));

  if (elems.length === 3) {
    return elems[0] * 60000 + elems[1] * 1000 + elems[2] * (onlyHundreths ? 10 : 1);
  } else if (elems.length === 2) {
    return elems[0] * 1000 + elems[1] * (onlyHundreths ? 10 : 1);
  } else {
    return elems[0] * (onlyHundreths ? 10 : 1);
  }
}

export function isSameDay(timestamp: number, referenceTimestamp?: number) {
  const date = new Date(timestamp);
  const now = referenceTimestamp ? new Date(referenceTimestamp) : new Date();

  if (now.getTime() - timestamp > 86_400_000) {
    return false;
  }

  return date.getDate() === now.getDate();
}

export function tagsIncludesTypeAndKart(tags: string[]) {
  const hasRaceTag = tags.reduce((prev, curr) => prev || !!raceTags.find((t) => t.text === curr), false);
  const hasKartTag = tags.reduce((prev, curr) => prev || !!kartTags.find((t) => t.text === curr), false);

  return hasRaceTag && hasKartTag;
}

export function tagSorter(a: string, b: string, kartTypeFirst?: boolean) {
  const raceTagsText = raceTags.map((t) => t.text);
  const kartTagsText = kartTags.map((t) => t.text);

  if (kartTagsText.includes(a)) {
    return 1 * (kartTypeFirst ? -1 : 1);
  } else if (kartTagsText.includes(b)) {
    return -1 * (kartTypeFirst ? -1 : 1);
  }

  if (raceTagsText.includes(a)) {
    return -1;
  } else if (raceTagsText.includes(b)) {
    return 1;
  }

  return a.localeCompare(b);
}

export function getSessionKartType(session: Session): string {
  return session.tags.find((t) => kartTags.map((t) => t.text).includes(t)) || "N/A";
}

export function isDd2Session(session?: Session) {
  if (!session) {
    return false;
  }
  return getSessionKartType(session) === KartType.DD2;
}

export function generateLaptimeWeightData(stints: StintExtended[]): LaptimeWeightGraphData {
  const data: LaptimeWeightGraphData = {
    weightMin: Number.MAX_SAFE_INTEGER,
    weightMax: Number.MIN_SAFE_INTEGER,
    timeMin: Number.MAX_SAFE_INTEGER,
    timeMax: Number.MIN_SAFE_INTEGER,
    yTicks: [],
    xTicks: [],
    driverData: [],
  };

  const preData: { [k in string]: { [k in number]: number } } = {};

  stints.forEach((s) => {
    if (!s.weight) {
      return;
    }

    const time = calculateTotalLaptime(s.time);

    if (!preData[s.driver]) {
      preData[s.driver] = {};
    }
    if (!preData[s.driver][s.weight]) {
      preData[s.driver][s.weight] = Number.MAX_SAFE_INTEGER;
    }

    preData[s.driver][s.weight] = Math.min(preData[s.driver][s.weight], time);
  });

  for (const driver in preData) {
    data.driverData.push({
      driver: driver,
      dataPoints: Object.entries(preData[driver])
        .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]))
        .map((d) => ({ weight: Number.parseInt(d[0]), time: d[1] })),
    });
  }

  data.driverData.forEach(({ dataPoints }) =>
    dataPoints.forEach(({ weight, time }) => {
      data.timeMin = Math.min(data.timeMin, time);
      data.timeMax = Math.max(data.timeMax, time);
      data.weightMin = Math.min(data.weightMin, weight);
      data.weightMax = Math.max(data.weightMax, weight);
    })
  );

  let timeMinSec = Math.floor(data.timeMin / 1000);
  const timeMaxSec = Math.ceil(data.timeMax / 1000);

  for (timeMinSec; timeMinSec <= timeMaxSec; timeMinSec++) {
    data.yTicks.push(timeMinSec * 1000);
  }

  let weightMin10s = Math.floor(data.weightMin / 10) + 1;
  let weightMax10s = Math.ceil(data.weightMax / 10);

  data.xTicks.push(data.weightMin - 1);
  for (weightMin10s; weightMin10s <= weightMax10s; weightMin10s++) {
    data.xTicks.push(weightMin10s * 10);
  }

  return data;
}

export function calculateLeaderboardEntries(stints: StintExtended[], weightLimit?: number): LeaderboardEntry[] {
  const leaderboardObj: { [k in string]: LeaderboardEntry } = {};
  const weightLim = weightLimit ? weightLimit : Number.MIN_SAFE_INTEGER;

  stints.forEach((s) => {
    const time = calculateTotalLaptime(s.time);

    if (s.weight >= weightLim) {
      if (!leaderboardObj[s.driver] || leaderboardObj[s.driver].time > time) {
        leaderboardObj[s.driver] = {
          time: time,
          splits: s.time,
          timestamp: s.session.timestamp,
          weight: s.weight,
          sessionId: s.sessionId,
          driver: s.driver,
        };
      }
    }
  });

  return Object.entries(leaderboardObj)
    .sort((a, b) => a[1].time - b[1].time)
    .map((res) => res[1]);
}

export function splitStintsInKartTypes(stints: StintExtended[], kartTypes: KartType[] | undefined) {
  if (!kartTypes) {
    return [];
  }

  return (
    kartTypes
      .map((kartType) => ({ kartType: kartType, stints: stints.filter((s) => s.session.tags.includes(kartType)) }))
      .filter((e) => e.stints.length > 0)
      .sort((a, b) => b.kartType.localeCompare(a.kartType)) || []
  );
}

export function placementToText(placement: number) {
  if (placement === 1) {
    return "1st";
  } else if (placement === 2) {
    return "2nd";
  } else if (placement === 3) {
    return "3rd";
  } else {
    return `${placement}th`;
  }
}

export function countItemsInSplitted(splittedItems: any[]) {
  return splittedItems.reduce((prev, curr) => prev + curr.items.reduce((p: number, c: any) => p + c.length, 0), 0);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export async function restoreScroll(elementId: string) {
  const scrollPos = await localForage.getItem(elementId);
  document.getElementById(elementId)?.scrollTo(0, scrollPos as number);
}

export function saveScrollPos(elementId: string) {
  localForage.setItem(elementId, document.getElementById(elementId)?.scrollTop);
}

export function backUrl(location: any) {
  return `back-url=${encodeURIComponent(location.pathname + location.search)}`;
}

export function calculateStats(
  driverName: string,
  sessions: Session[],
  allStints: StintExtended[],
  myStints: StintExtended[]
): {
  stints: { total: number };
  tracks: { total: number; ratio: number };
  medalsInfo: { gold: MedalInfo[]; silver: MedalInfo[]; bronze: MedalInfo[]; other: MedalInfo[] };
} {
  const tracksSet = new Set();

  myStints.forEach((stint) => {
    tracksSet.add(stint.session.track);
  });

  const stintMap = new Map<string, StintExtended[]>();

  allStints.forEach((stint) => {
    const key = stint.session.track + ";" + stint.session.layout + ";" + getSessionKartType(stint.session);
    stintMap.set(key, (stintMap.get(key) || []).concat(stint));
  });

  const medals: MedalInfo[] = [];

  for (const track of TRACKS) {
    for (const layout of track.layouts) {
      for (const kartType in KartType) {
        for (const weightLim of kartType === KartType.DD2 ? [0, 175] : [0, ...WEIGHT_CLASSES]) {
          const stints = stintMap.get(track.id + ";" + layout.id + ";" + kartType);
          if (stints && stints.length > 0) {
            const leaderboard = calculateLeaderboardEntries(stints, weightLim);
            const pos = leaderboard.findIndex((entry) => entry.driver === driverName);
            if (pos !== -1) {
              medals.push({
                pos: pos + 1,
                trackName: getPrettyTrackName(track.id, layout.id),
                kartType: kartType,
                weightLimit: weightLim,
                sessionId: leaderboard[pos].sessionId,
                timestamp: leaderboard[pos].timestamp,
              });
            }
          }
        }
      }
    }
  }

  return {
    stints: {
      total: myStints.length,
    },
    tracks: {
      total: tracksSet.size,
      ratio: tracksSet.size / TRACKS.length,
    },
    medalsInfo: {
      gold: medals.filter((m) => m.pos === 1),
      silver: medals.filter((m) => m.pos === 2),
      bronze: medals.filter((m) => m.pos === 3),
      other: medals.filter((m) => m.pos > 3),
    },
  };
}

export function removeBorderOnMiddleItem(list: any[], index: number): CSSProperties {
  if (list.length - 1 !== index) {
    return {
      borderBottom: undefined,
    };
  }

  return {};
}

export function calculateDelta(fastestTime: number, time: number, onlyHundreths?: boolean) {
  let delta = time - fastestTime;

  if (delta === 0) {
    return "";
  }

  const seconds = delta < 0 ? Math.ceil(delta / 1000) : Math.floor(delta / 1000);
  const thousands = Math.abs(delta % 1000);

  return (
    (delta >= 0 ? "+" : "-") +
    seconds +
    ":" +
    ((onlyHundreths ? Math.floor(thousands / 10) : thousands) + "").padStart(onlyHundreths ? 2 : 3, "0").padEnd(onlyHundreths ? 2 : 3, "0")
  );
}

export function makeLeaderboard(stints: Stint[]) {
  const leaderboardObj: {
    [k in string]: { time: number; weight: number; kartNumber: number | null; stintId: string; youtubeUrls: string[] };
  } = {};
  stints.forEach((s) => {
    const time = calculateTotalLaptime(s.time);
    if (leaderboardObj[s.driver] || Number.MAX_SAFE_INTEGER > time) {
      leaderboardObj[s.driver] = {
        time: time,
        weight: s.weight,
        kartNumber: s.kartNumber,
        stintId: s._id,
        youtubeUrls: s.youtubeUrls,
      };
    }
  });

  return Object.entries(leaderboardObj)
    .sort((a, b) => a[1].time - b[1].time)
    .map((res) => ({ driver: res[0], ...res[1] }));
}

export function roundTimestamp(timestamp: number | undefined) {
  if (!timestamp) {
    return [0, 0];
  }
  timestamp -= timestamp % (24 * 60 * 60 * 1000); //subtract amount of time since midnight
  timestamp += new Date().getTimezoneOffset() * 60 * 1000; //add on the timezone offset
  return [timestamp, timestamp + 86_400_400];
}

export function calculateGokartComparisonLaptimes(sessions: Session[]) {
  const gokartToTimes: { [k in number]: { [k in string]: StintExtended } } = {};

  sessions.forEach((session) =>
    session.stints.forEach((s) => {
      if (!s.kartNumber) {
        return;
      }
      if (!gokartToTimes[s.kartNumber]) {
        gokartToTimes[s.kartNumber] = {};
      }
      if (!gokartToTimes[s.kartNumber][s.driver]) {
        gokartToTimes[s.kartNumber][s.driver] = { ...s, session: session, drivers: [] };
      } else {
        const time = calculateTotalLaptime(s.time);
        if (calculateTotalLaptime(gokartToTimes[s.kartNumber][s.driver].time) > time) {
          gokartToTimes[s.kartNumber][s.driver] = { ...s, session: session, drivers: [] };
        }
      }
    })
  );

  const result: { kartNumber: number; stints: StintExtended[] }[] = [];

  Object.entries(gokartToTimes).forEach(([kartNumber, driverStints]) =>
    result.push({
      kartNumber: Number.parseInt(kartNumber),
      stints: Object.values(driverStints).sort((a, b) => calculateTotalLaptime(a.time) - calculateTotalLaptime(b.time)),
    })
  );

  result.sort((a, b) => calculateTotalLaptime(a.stints[0].time) - calculateTotalLaptime(b.stints[0].time));

  return result;
}

export function calculateTotalLaptime(sectorTimes: number[]) {
  return sectorTimes.reduce((prev, curr) => prev + curr, 0);
}

export function indexToPosition(index: number) {
  const leastSignificant = index % 10;
  const pos = index + 1;
  if (leastSignificant === 0) {
    return pos + "st";
  } else if (leastSignificant === 1) {
    return pos + "nd";
  } else if (leastSignificant === 2) {
    return pos + "rd";
  }

  return pos + "th";
}

export function calculatePlacement(session: Session | undefined, driver: string) {
  if (!session) {
    return -1;
  }
  const sortedStints = session.stints.sort((a, b) => calculateTotalLaptime(a.time) - calculateTotalLaptime(b.time));
  return sortedStints.findIndex((s) => s.driver === driver);
}

export async function forceRefresh() {
  // const reg = await navigator.serviceWorker.getRegistration();
  // if (reg) {
  //   await reg.unregister();
  // }
  window.location.reload();
}

export function checkVersionRefresh() {
  doFetch(
    "GET",
    `https://api.${DOMAIN}/version/gokart`,
    (response) => {
      localForage.getItem(LF_REFRESH_TARGET, async (_err, refreshTarget) =>
        localForage.getItem(LF_REFRESH_TARGET_DENY, (_err, refreshTargetDeny) => {
          if (response !== refreshTarget && response !== refreshTargetDeny) {
            if (response !== process.env.REACT_APP_VERSION) {
              if (window.confirm("A newer version of the app is available. Update now?")) {
                localForage.setItem(LF_REFRESH_TARGET, response).then(forceRefresh);
              } else {
                localForage.setItem(LF_REFRESH_TARGET_DENY, response);
              }
            }
          } else {
            localForage.setItem(LF_REFRESH_TARGET, "");
          }
        })
      );
    },
    (err) => alert(`Fetching newest version: ${err}`),
    undefined,
    undefined,
    true,
    true
  );
}
