import React, { useEffect } from "react";
import { saveScrollPos, restoreScroll } from "../../lib/functions";
import * as localForage from "localforage";
import { LF_RESTORE_SCROLL } from "../../lib/definitions";

interface ScrollableContainerProps {
  children: React.ReactNode;
  height?: number;
  id?: string;
}

export function ScrollableContainer(props: ScrollableContainerProps) {
  useEffect(() => {
    if (props.id) {
      localForage.getItem(LF_RESTORE_SCROLL, (_err, shouldRestore) => {
        if (shouldRestore) {
          restoreScroll(props.id!);
          localForage.setItem(LF_RESTORE_SCROLL, false);
        }
      });
    }
  }, [props.id]);

  return (
    <div
      style={{ height: props.height || "100vh", overflowY: "scroll", overflowX: "hidden" }}
      id={props.id}
      onClick={() => props.id && saveScrollPos(props.id)}
    >
      {props.children}
      <div style={{ height: "200px" }} />
    </div>
  );
}
