import { CSSProperties } from "react";

interface ButtonBarProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export function ButtonBar(props: ButtonBarProps) {
  return <div style={{ margin: "0 16px 20px 0", display: "flex", justifyContent: "flex-end", ...props.style }}>{props.children}</div>;
}
