import { UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { NavigationBarItem } from "./navigationBarItem";
import circuit from "../lib/images/circuit.png";
import { useHistory } from "react-router";
import { NavItem } from "../lib/models";

interface NavigationBarProps {
  currentNavItem: NavItem;
}

export function NavigationBar(props: NavigationBarProps) {
  const history = useHistory();

  const divider = <div style={{ height: "60%", width: "2px", background: "lightgray" }} />;
  return (
    <div className="navigation-bar">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr auto 1fr",
          gridAutoRows: "1fr",
          alignItems: "center",
          height: "100%",
        }}
      >
        <NavigationBarItem
          onClick={() => history.push("/tracks")}
          icon={
            <img
              src={circuit}
              alt=""
              className={"navigation-bar__image" + (props.currentNavItem === "Track" ? " navigation-bar__image--highlight" : "")}
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => history.push("/stints")}
          icon={
            <UnorderedListOutlined
              className={"navigation-bar__icon" + (props.currentNavItem === "Stint" ? " navigation-bar__icon--highlight" : "")}
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => history.push("/user")}
          icon={
            <UserOutlined
              className={"navigation-bar__icon" + (props.currentNavItem === "User" ? " navigation-bar__icon--highlight" : "")}
            />
          }
        />
      </div>
    </div>
  );
}
