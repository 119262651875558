import { msToLaptime } from "../lib/functions";
import { KartType } from "../lib/models";
import { Tag } from "../components/general/tag";
import { TRACKS } from "../lib/definitions";
import React from "react";

interface TrackCardProps {
  id: string;
  name: string;
  bestLaptimes: { layout: string; laptimes: { kartType: KartType; laptime: number }[] }[];
  onClick(): void;
}

export function TrackCard(props: TrackCardProps) {
  return (
    <div
      style={{
        marginBottom: "24px",
        boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
        color: "#fafafa",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundImage: `url('/assets/${props.id}.png')`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          width: "100%",
          height: "240px",
          position: "relative",
        }}
      >
        {props.id && (
          <img
            src={`/assets/${props.id}-logo.png`}
            alt=""
            style={{
              objectFit: "contain",
              maxWidth: "50%",
              maxHeight: "40px",
              filter: "drop-shadow(0 0 12px black)",
              borderRadius: "4px",
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          />
        )}
      </div>
      <div
        style={{
          borderTop: "1px solid darkgray",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0px",
          width: "100%",
        }}
        className="backdrop-blur"
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            whiteSpace: "nowrap",
            margin: "16px 0 16px 20px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.name}
        </div>
        <div
          style={{
            marginRight: "16px",
            display: "grid",
            gridTemplateColumns: "repeat(3, min-content)",
            alignItems: "center",
          }}
        >
          {props.bestLaptimes.map(({ layout, laptimes }) => {
            const layoutName = TRACKS.find((t) => t.id === props.id)?.layouts.find((l) => l.id + "" === layout)?.name;
            return laptimes.map(({ kartType, laptime }) => (
              <React.Fragment key={kartType + "." + layout}>
                <div style={{ textAlign: "end", fontSize: "15px" }}>{msToLaptime([laptime], kartType === KartType.DD2)}</div>
                <Tag text={kartType} noText style={{ marginBottom: "-2px", marginLeft: "8px" }} />
                <div
                  style={{
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                    marginBottom: "-1px",
                    marginLeft: layoutName !== "Default" ? "8px" : undefined,
                  }}
                >
                  {layoutName === "Default" ? "" : layoutName}
                </div>
              </React.Fragment>
            ));
          })}
        </div>
      </div>
    </div>
  );
}
