import { MoreOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { DataContext } from "../lib/contexts";
import { indexToPosition, msToLaptime } from "../lib/functions";
import { Leaderboard } from "../lib/models";

interface LeaderboardCardContentProps {
  leaderboards: Leaderboard[];
}

export function LeaderboardCardContent(props: LeaderboardCardContentProps) {
  const { getDriverName, userInfo } = useContext(DataContext);

  const ownPlacements = useMemo(() => {
    const result: { [k in string]: number } = {};
    props.leaderboards.forEach(
      (leaderboard) =>
        (result[leaderboard.kartType] = leaderboard.leaderboardEntries.findIndex((entry) => entry.driver === userInfo.email) + 1)
    );
    return result;
  }, [props.leaderboards, userInfo.email]);

  return (
    <div>
      {props.leaderboards.length === 0 ? (
        <div style={{ fontSize: 14, textAlign: "center", color: "#e9e9e9" }}>No laptimes have been registered</div>
      ) : (
        props.leaderboards.map((leaderboard, i) => (
          <React.Fragment key={leaderboard.kartType}>
            {props.leaderboards.length > 1 && (
              <div style={{ marginTop: i !== 0 ? "16px" : undefined, fontSize: "16px", fontStyle: "italic" }}>{leaderboard.kartType}</div>
            )}
            <div style={{ marginTop: "8px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "min-content auto min-content",
                  fontSize: "15px",
                  columnGap: "16px",
                  rowGap: "4px",
                }}
              >
                {leaderboard.leaderboardEntries.slice(0, ownPlacements[leaderboard.kartType] <= 5 ? 5 : 3).map((entry, i) => (
                  <React.Fragment key={entry.driver}>
                    <div>{indexToPosition(i)}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{getDriverName(entry.driver)}</div>

                    <div style={{ textAlign: "end" }}>{msToLaptime([entry.time])}</div>
                  </React.Fragment>
                ))}
                {ownPlacements[leaderboard.kartType] > 5 && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <MoreOutlined />
                    </div>
                    <div style={{ textAlign: "center", marginRight: "32px" }}>
                      <MoreOutlined />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <MoreOutlined />
                    </div>
                    <>
                      <div>{indexToPosition(ownPlacements[leaderboard.kartType] - 1)}</div>
                      <div>{getDriverName(userInfo.email)}</div>
                      <div style={{ textAlign: "end" }}>
                        {msToLaptime([leaderboard.leaderboardEntries[ownPlacements[leaderboard.kartType] - 1].time])}
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        ))
      )}
    </div>
  );
}
