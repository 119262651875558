import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Header } from "../components/general/header";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { Spinner } from "../components/general/spinner";
import { Title } from "../components/general/title";
import { LaptimeWeightGraph } from "../components/laptimeWeightGraph";
import { StandardLeaderboard } from "../components/standardLeaderboard";
import { TrackLogoBanner } from "../components/trackLogoBanner";
import { DataContext } from "../lib/contexts";
import { LF_SCROLL_POS_LEADERBOARDS, TRACKS, WEIGHT_CLASSES } from "../lib/definitions";
import { calculateLeaderboardEntries, splitStintsInKartTypes } from "../lib/functions";
import { KartType, Leaderboard, WeightLeaderboard } from "../lib/models";

export function LeaderboardsScreen() {
  const { allStints, isWorking, setCurrentNavItem } = useContext(DataContext);
  const { trackId, layoutId } = useParams<{ trackId: string; layoutId: string }>();

  useEffect(() => setCurrentNavItem("Track"), [setCurrentNavItem]);

  const kartTypeStints = useMemo(() => {
    const trackStints = allStints.filter((s) => s.session.track === trackId && s.session.layout + "" === layoutId);
    return splitStintsInKartTypes(trackStints, TRACKS.find((t) => t.id === trackId)?.kartTypes);
  }, [allStints, trackId, layoutId]);

  const absoluteLeaderboards: Leaderboard[] = useMemo(
    () => kartTypeStints.map(({ kartType, stints }) => ({ kartType: kartType, leaderboardEntries: calculateLeaderboardEntries(stints) })),
    [kartTypeStints]
  );

  const weightKartLeaderboards: { kartType: KartType; weightLeaderboards: WeightLeaderboard[] }[] = useMemo(
    () =>
      kartTypeStints
        .map(({ kartType, stints }) => ({
          kartType,
          weightLeaderboards: (kartType === KartType.DD2 ? [175] : WEIGHT_CLASSES)
            .map((weight) => ({
              weightLimit: weight,
              leaderboardEntries: calculateLeaderboardEntries(stints, weight),
            }))
            .filter((l) => l.leaderboardEntries.length > 0),
        }))
        .filter((l) => l.weightLeaderboards.length > 0),
    [kartTypeStints]
  );

  return (
    <ScrollableContainer id={LF_SCROLL_POS_LEADERBOARDS}>
      <TrackLogoBanner trackId={trackId} layout={layoutId} />
      <Header level={1} title="Leaderboards" onBackUrl={`/tracks/${trackId}/${layoutId}`} />
      <Header
        level={2}
        title="Absolute Records"
        smallTopMargin
        style={{ background: "none", boxShadow: "none", border: "none", justifyContent: "center" }}
        noBack
      />
      {isWorking && absoluteLeaderboards.length === 0 ? (
        <Spinner />
      ) : absoluteLeaderboards.length === 0 ? (
        <div className="placeholder">No times yet...</div>
      ) : (
        absoluteLeaderboards.map((leaderboard, i) => (
          <React.Fragment key={leaderboard.kartType}>
            {absoluteLeaderboards.length > 1 && <Title text={leaderboard.kartType} smallTopMargin={i === 0} style={{ fontSize: "15px" }} />}
            <StandardLeaderboard
              leaderboardEntries={leaderboard.leaderboardEntries}
              withSplitsOption={leaderboard.kartType === "DD2"}
              onlyHundreths={leaderboard.kartType === "DD2"}
            />
          </React.Fragment>
        ))
      )}
      <Header
        level={2}
        title="Weight Class Records"
        style={{ background: "none", boxShadow: "none", border: "none", justifyContent: "center" }}
        noBack
      />
      {isWorking && weightKartLeaderboards.length === 0 ? (
        <Spinner />
      ) : weightKartLeaderboards.length === 0 ? (
        <div className="placeholder">No times yet...</div>
      ) : (
        weightKartLeaderboards.map(({ kartType, weightLeaderboards }, i) =>
          weightLeaderboards.map(({ weightLimit, leaderboardEntries }, j) => {
            const kartText = weightKartLeaderboards.length > 1 ? kartType : "";
            const weightText = weightLeaderboards.length > 1 ? weightLimit : "";
            const titleText = `${kartText}${kartText && weightText ? " - " : ""}${weightLimit ? weightLimit + "kg" : ""}`;
            return (
              <React.Fragment key={kartType + weightLimit}>
                <Title text={titleText} smallTopMargin={i + j === 0} style={{ fontSize: "15px" }} />
                <StandardLeaderboard leaderboardEntries={leaderboardEntries} />
              </React.Fragment>
            );
          })
        )
      )}
      <Header
        level={2}
        title="Laptime/Weight"
        style={{ background: "none", boxShadow: "none", border: "none", justifyContent: "center" }}
        noBack
      />
      {isWorking && kartTypeStints.length === 0 ? (
        <Spinner />
      ) : kartTypeStints.length === 0 ? (
        <div className="placeholder">No times yet...</div>
      ) : (
        kartTypeStints.map(({ kartType, stints }, i) => (
          <React.Fragment key={kartType}>
            {kartTypeStints.length > 1 && <Title text={kartType} smallTopMargin={i === 0} style={{ fontSize: "15px" }} />}
            <LaptimeWeightGraph stints={stints} />
          </React.Fragment>
        ))
      )}
    </ScrollableContainer>
  );
}
