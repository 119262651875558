import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../components/general/button";
import { Header } from "../components/general/header";
import { Tag } from "../components/general/tag";
import { DataContext } from "../lib/contexts";
import { NumberInput } from "../components/general/numberInput";
import * as localForage from "localforage";
import {
  DD2_DRIVERS,
  DOMAIN,
  LF_DD2_KART_NUMBER,
  LF_DD2_WEIGHT,
  LF_SCROLL_POS_MEDALLIST,
  LF_SCROLL_POS_USER,
  LF_SHOW_WEIGHT,
  LF_WEIGHT,
} from "../lib/definitions";
import { SectionContainer } from "../components/general/sectionContainer";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { InfoItem } from "../components/general/infoItem";
import { NumberPlate } from "../components/numberplate";
import { StatBar } from "../components/general/statBar";
import { backUrl, calculateStats, forceRefresh, getPrettyTrackName, getSessionKartType, timestampToPrettyDate } from "../lib/functions";
import { Switch } from "antd";
import { ListItemCell } from "../components/general/listItemCell";
import { Medal } from "../components/medal";
import { MedalInfo, Session } from "../lib/models";
import { useHistory, useLocation } from "react-router-dom";
import { TimeStampAsDate } from "../components/timestampAsDate";

interface UserScreenProps {
  onLogout(): void;
}

export function UserScreen(props: UserScreenProps) {
  const [weight, setWeight] = useState("");
  const [dd2Weight, setDd2Weight] = useState("");
  const [dd2KartNumber, setDd2KartNumber] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showWeight, setShowWeight] = useState(true);
  const [showMedalsDetails, setShowMedalsDetails] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const medalListScrollPos = useRef<number>();

  const { setCurrentNavItem } = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const medalListScroll = Number(await localForage.getItem(LF_SCROLL_POS_MEDALLIST)) || 0;
      if (medalListScroll > 0) {
        setShowMedalsDetails(true);
        medalListScrollPos.current = medalListScroll;
      }

      localForage.getItem(LF_WEIGHT, (_err, val) => val && setWeight(val + ""));
      localForage.getItem(LF_DD2_WEIGHT, (_err, val) => val && setDd2Weight(val + ""));
      localForage.getItem(LF_DD2_KART_NUMBER, (_err, val) => val && setDd2KartNumber(val + ""));
      localForage.getItem(LF_SHOW_WEIGHT, (_err, val) => setShowWeight(!!val));
    })();
  }, []);

  useEffect(() => setCurrentNavItem("User"), [setCurrentNavItem]);

  useEffect(() => {
    if (weight) {
      localForage.setItem(LF_WEIGHT, Number.parseInt(weight));
    }
  }, [weight]);

  useEffect(() => {
    if (dd2Weight) {
      localForage.setItem(LF_DD2_WEIGHT, Number.parseInt(dd2Weight));
    }
  }, [dd2Weight]);

  useEffect(() => {
    if (dd2KartNumber) {
      localForage.setItem(LF_DD2_KART_NUMBER, Number.parseInt(dd2KartNumber));
    }
  }, [dd2KartNumber]);

  useEffect(() => {
    if (showMedalsDetails && medalListScrollPos.current && medalListScrollPos.current > 0) {
      document.getElementById(LF_SCROLL_POS_MEDALLIST)?.scrollTo(0, medalListScrollPos.current);
    }
  }, [showMedalsDetails]);

  const { userInfo, allStints, sessions, myStints } = useContext(DataContext);

  const stats = useMemo(
    () => calculateStats(userInfo.email, sessions, allStints, myStints),
    [userInfo.email, sessions, allStints, myStints]
  );

  const getMedalInfoRow = (medalInfo: MedalInfo) => {
    function onClick() {
      const session = sessions.find((s) => s._id === medalInfo.sessionId);
      history.push(`/tracks/${session?.track}/${session?.layout}/leaderboards?${backUrl(location)}`);
      localForage.setItem(LF_SCROLL_POS_MEDALLIST, document.getElementById(LF_SCROLL_POS_MEDALLIST)?.scrollTop);
    }

    return (
      <React.Fragment key={medalInfo.kartType + medalInfo.trackName + medalInfo.weightLimit}>
        <ListItemCell space={[12, 6]} onClick={onClick}>
          {medalInfo.pos < 4 ? (
            <Medal
              style={{ fontSize: "20px", marginBottom: "-4px" }}
              type={medalInfo.pos === 1 ? "Gold" : medalInfo.pos === 2 ? "Silver" : "Bronze"}
            />
          ) : (
            <div>{medalInfo.pos + "."}</div>
          )}
        </ListItemCell>
        <ListItemCell space={8} onClick={onClick} style={{ fontSize: "15px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div>{medalInfo.trackName}</div>
            <div style={{ fontSize: 12, color: "#8b8b8b" }}>{timestampToPrettyDate(medalInfo.timestamp, true, true)}</div>
          </div>
        </ListItemCell>
        <ListItemCell space={[6, 12]} style={{ textAlign: "end" }} onClick={onClick}>
          <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
            <Tag marginSide="none" text={medalInfo.kartType} style={{ padding: "2px 4px", fontSize: "10px" }} />
            <div style={{ fontSize: 12, height: 18.85, margin: "2px 0 -2px 0" }}>
              {medalInfo.weightLimit ? medalInfo.weightLimit + "kg" : ""}
            </div>
          </div>
        </ListItemCell>
      </React.Fragment>
    );
  };

  const medalInfos = [...stats.medalsInfo.gold, ...stats.medalsInfo.silver, ...stats.medalsInfo.bronze, ...stats.medalsInfo.other];

  const emptySessions = useMemo(() => sessions.filter((s) => s.stints.length === 0), [sessions]);

  function onEmptySessionClick(session: Session) {
    history.push(`/sessions/${session._id}`);
  }

  return (
    <ScrollableContainer id={LF_SCROLL_POS_USER}>
      <Header level={1} title="Profile" />
      <Header level={2} title="Driver details" smallTopMargin />
      <SectionContainer style={{ fontSize: "20px" }}>
        <InfoItem label="Email" value={userInfo.email} />
        <InfoItem
          label="Name"
          value={userInfo.firstname + " " + userInfo.lastname}
          onEdit={() => (window.location.href = `https://${DOMAIN}/edit-profile?redirect=${window.location.href}`)}
        />
        <InfoItem
          label="Nickname"
          value={userInfo.nickname}
          onEdit={() => (window.location.href = `https://${DOMAIN}/edit-profile?redirect=${window.location.href}`)}
        />
        <InfoItem
          label="Weight"
          value={
            <div style={{ display: "flex" }}>
              <Switch
                checked={showWeight}
                unCheckedChildren="Hidden"
                checkedChildren="Shown"
                style={{ marginRight: "8px" }}
                onChange={() => {
                  setShowWeight(!showWeight);
                  localForage.setItem(LF_SHOW_WEIGHT, !showWeight);
                }}
              />
              {showWeight && `${weight} kg`}
            </div>
          }
          editComponent={
            showWeight
              ? (onSave) => (
                  <NumberInput
                    autoFocus
                    value={weight}
                    onBlur={onSave}
                    onChange={setWeight}
                    style={{ marginRight: "4px", width: "40px", fontFamily: "unset" }}
                  />
                )
              : undefined
          }
        />
        {DD2_DRIVERS.includes(userInfo.email) && (
          <>
            <InfoItem
              label="DD2 weight"
              value={`${dd2Weight} kg`}
              editComponent={(onSave) => (
                <NumberInput
                  autoFocus
                  value={dd2Weight}
                  onBlur={onSave}
                  onChange={setDd2Weight}
                  style={{ marginRight: "4px", width: "40px", fontFamily: "unset" }}
                />
              )}
            />
            <InfoItem
              label="DD2 kart number"
              value={<NumberPlate number={dd2KartNumber} big />}
              editComponent={(onSave) => (
                <NumberInput
                  autoFocus
                  value={dd2KartNumber}
                  onChange={setDd2KartNumber}
                  onBlur={onSave}
                  style={{ marginRight: "4px", width: "40px", fontFamily: "unset" }}
                />
              )}
            />
          </>
        )}
      </SectionContainer>
      <Header level={2} title="Statistics" />
      <SectionContainer>
        <InfoItem label="Tracks driven" value={<StatBar value={stats.tracks.total} ratio={stats.tracks.ratio} />} />
        <InfoItem label="Stints driven" value={<div style={{ fontStyle: "italic" }}>{stats.stints.total}</div>} />
        <InfoItem
          label="Medals earned"
          value={
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  fontSize: "20px",
                  marginTop: "8px",
                  fontStyle: "italic",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Medal type="Gold" style={{ fontSize: "24px", marginRight: "8px" }} />
                  {stats.medalsInfo.gold.length}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Medal type="Silver" style={{ fontSize: "24px", marginRight: "8px" }} />
                  {stats.medalsInfo.silver.length}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Medal type="Bronze" style={{ fontSize: "24px", marginRight: "8px" }} />
                  {stats.medalsInfo.bronze.length}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Medal type="Other" style={{ fontSize: "24px", marginRight: "8px" }} />
                  {stats.medalsInfo.other.length}
                </div>
              </div>
              {showMedalsDetails && (
                <div
                  id={LF_SCROLL_POS_MEDALLIST}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "min-content auto min-content",
                    rowGap: "4px",
                    fontSize: "16px",
                    marginTop: "16px",
                    height: "280px",
                    overflow: "auto",
                    border: "1px solid #c4c4c4",
                    borderRadius: "16px",
                    margin: "24px -24px 0 -24px",
                    paddingTop: "4px",
                    gridAutoRows: "min-content",
                  }}
                >
                  {medalInfos.length === 0 ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        gridColumn: "span 3",
                        marginTop: "24px",
                        fontStyle: "italic",
                      }}
                    >
                      No medals earned...
                    </div>
                  ) : (
                    medalInfos.map(getMedalInfoRow)
                  )}
                  <div style={{ height: "2px" }} />
                </div>
              )}
              <div style={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
                <Button text={`${showMedalsDetails ? "Hide" : "Show"} Details`} onClick={() => setShowMedalsDetails(!showMedalsDetails)} />
              </div>
            </div>
          }
        />
      </SectionContainer>
      {userInfo.email === "thisbecasper@gmail.com" && (
        <>
          <Header level={1} title="Administration" />
          <Header level={2} title="Empty sessions" smallTopMargin />
          {emptySessions.length === 0 ? (
            <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>No empty sessions...</div>
          ) : (
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content",
              }}
            >
              {emptySessions.map((s) => (
                <React.Fragment key={s._id}>
                  <ListItemCell space={8} align="center" onClick={() => onEmptySessionClick(s)} style={{ fontSize: "12px" }}>
                    <TimeStampAsDate timestamp={s.timestamp} />
                  </ListItemCell>
                  <ListItemCell onClick={() => onEmptySessionClick(s)}>{getPrettyTrackName(s.track, s.layout)}</ListItemCell>
                  <ListItemCell space={[8, 12]} style={{ fontSize: "14px" }} onClick={() => onEmptySessionClick(s)}>
                    <Tag text={getSessionKartType(s) || ""} style={{ fontSize: "12px", padding: "3px 6px" }} />
                  </ListItemCell>
                </React.Fragment>
              ))}
            </div>
          )}
        </>
      )}
      <Header level={1} title="Other" />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "36px" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px" }}>
          <div style={{ marginRight: "16px" }}>Version:</div>
          <div style={{ fontWeight: "bold" }}>{process.env.REACT_APP_VERSION}</div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <Button
            text="Update WebApp"
            loading={isRefreshing}
            onClick={() => {
              setIsRefreshing(true);
              forceRefresh();
              window.location.assign(window.location.protocol + window.location.host + "/gokart/tracks");
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "72px" }}>
        <Button text="Logout" onClick={props.onLogout} />
      </div>
    </ScrollableContainer>
  );
}
