import { DeleteOutlined, MinusOutlined, PlusOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button } from "../components/general/button";
import { Header } from "../components/general/header";
import { ListItemCell } from "../components/general/listItemCell";
import { NumberInput } from "../components/general/numberInput";
import { RadioGroup } from "../components/general/radioGroup";
import { RadioButton } from "../components/general/radioButton";
import { ScrollableContainer } from "../components/general/scrollableContainer";
import { TimeInput } from "../components/general/timeInput";
import { DataContext } from "../lib/contexts";
import { doFetch, getPrettyTrackName, isDd2Session, isSameDay, laptimeToMs, msToLaptime, tagSorter, useQuery } from "../lib/functions";
import * as localForage from "localforage";
import { APIResources, LF_DD2_KART_NUMBER, LF_DD2_WEIGHT, LF_SHOW_WEIGHT, LF_WEIGHT, TRACKS } from "../lib/definitions";
import { SectionContainer } from "../components/general/sectionContainer";
import { TimeStampAsDate } from "../components/timestampAsDate";
import { Tag } from "../components/general/tag";
import { Session } from "../lib/models";
import { TrackLogoBanner } from "../components/trackLogoBanner";

export function UpsertStintScreen() {
  const [trackNLayout, setTrackNLayout] = useState<{ trackId: string; layout: string }>();
  const [querySessionId, setQuerySessionId] = useState("");
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [lapTimeSelected, setLapTimeSelected] = useState(true);
  const [sectors, setSectors] = useState<string[]>(["", "", ""]);
  const [inputValue, setInputValue] = useState("");
  const [weight, setWeight] = useState("");
  const [kartNumber, setKartNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [favorited, setFavorited] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [driverOverwriteValue, setDriverOverwriteValue] = useState("");
  const [dd2Session, setDd2Session] = useState(false);
  const [youtubeUrls, setYoutubeUrls] = useState<string[]>([]);
  const [newUrl, setNewUrl] = useState("");

  const { uncoupledSessions, refresh, allStints, setIsInputting, userInfo, allUsers, sessions, setCurrentNavItem } =
    useContext(DataContext);

  const { stintId } = useParams<{ stintId: string }>();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get("track") || "";

    if (query) {
      const elems = query.split(";");
      setTrackNLayout({ trackId: elems[0], layout: elems[1] });
    }
  }, []);

  useEffect(() => setCurrentNavItem("Stint"), [setCurrentNavItem]);

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get("sessionid") || "";
    if (sessionId) {
      setQuerySessionId(sessionId);
      setSelectedSessionId(sessionId);
      const session = sessions.find((s) => s._id === sessionId);
      if (session) {
        setTrackNLayout({ trackId: session.track, layout: session.layout + "" });
      }
    }
  }, [sessions]);

  useEffect(() => {
    const session = sessions.find((s) => s._id === selectedSessionId);
    if (session) {
      setDd2Session(isDd2Session(session));
    }
  }, [selectedSessionId, sessions]);

  useEffect(() => {
    (async () => {
      if (stintId) {
        const stint = allStints.find((s) => s._id === stintId);
        if (stint) {
          setSelectedSessionId(stint.sessionId);
          if (stint.time.length === 1) {
            setLapTimeSelected(true);
            setInputValue(msToLaptime(stint.time, isDd2Session(stint.session)));
          } else {
            setLapTimeSelected(false);
            setSectors(stint.time.map((t) => msToLaptime([t], isDd2Session(stint.session))));
          }

          const showWeight = await localForage.getItem(LF_SHOW_WEIGHT);

          if (stint.weight) {
            setWeight(stint.weight + "");
          } else {
            localForage.getItem(isDd2Session(stint.session) ? LF_DD2_WEIGHT : showWeight ? LF_WEIGHT : "", (_, val) => setWeight(val + ""));
          }

          if (stint.kartNumber) {
            setKartNumber(stint.kartNumber + "");
          } else if (isDd2Session(stint.session)) {
            localForage.getItem(LF_DD2_KART_NUMBER, (_, val) => setKartNumber(val + ""));
          }

          setNotes(stint.notes);
          if (userInfo.email === "thisbecasper@gmail.com") {
            setDriverOverwriteValue(stint.driver);
          }

          setYoutubeUrls(stint.youtubeUrls);
        }
      }
    })();
  }, [stintId, allStints, userInfo.email]);

  function updateSector(sectorNo: number, newTime: string) {
    const newSectors = [...sectors];
    newSectors[sectorNo - 1] = newTime;
    setSectors(newSectors);
  }

  function updateYoutubeUrls(urlNo: number, newUrl: string) {
    const newUrls = [...youtubeUrls];
    newUrls[urlNo] = newUrl;
    setYoutubeUrls(newUrls.filter((u) => !!u));
  }

  const uncoupledSessionsFiltered = useMemo(() => {
    if (querySessionId) {
      return uncoupledSessions.filter((s) => s._id === querySessionId);
    }

    if (trackNLayout) {
      return uncoupledSessions.filter((s) => s.track === trackNLayout.trackId && s.layout + "" === trackNLayout.layout);
    }

    return uncoupledSessions;
  }, [uncoupledSessions, trackNLayout, querySessionId]);

  const uncoupledToday = useMemo(
    () => uncoupledSessionsFiltered.filter((s) => isSameDay(s.timestamp)),

    [uncoupledSessionsFiltered]
  );

  const uncoupledRest = useMemo(() => uncoupledSessionsFiltered.filter((s) => !isSameDay(s.timestamp)), [uncoupledSessionsFiltered]);

  function onSessionOptionCellClick(session: Session) {
    setSelectedSessionId(session._id);
    setSectors((sectors) => sectors.map(() => ""));
    setInputValue("");
  }

  const getSessionOptions = (sessions: Session[]) =>
    sessions.map((s) => (
      <React.Fragment key={s._id}>
        <ListItemCell space={8} onClick={() => onSessionOptionCellClick(s)} innerStyle={{ paddingRight: "2px" }}>
          <TimeStampAsDate
            timestamp={s.timestamp}
            withYear
            style={{ fontSize: "12px", color: selectedSessionId === s._id ? "#1a90ff" : undefined }}
          />
        </ListItemCell>
        <ListItemCell onClick={() => onSessionOptionCellClick(s)} style={{ color: selectedSessionId === s._id ? "#1a90ff" : undefined }}>
          {getPrettyTrackName(s.track, s.layout)}
        </ListItemCell>
        <ListItemCell align="end" onClick={() => onSessionOptionCellClick(s)}>
          {s.tags.sort(tagSorter).map((t) => (
            <Tag key={t} noText text={t} />
          ))}
        </ListItemCell>
        <ListItemCell space={8} onClick={() => onSessionOptionCellClick(s)}>
          <RadioButton checked={selectedSessionId === s._id} />
        </ListItemCell>
      </React.Fragment>
    ));

  return (
    <ScrollableContainer>
      {trackNLayout && <TrackLogoBanner trackId={trackNLayout.trackId} layout={trackNLayout.layout} />}
      <Header level={1} title={`${stintId ? "Update" : "New"} stint`} onBackUrl={"/stints"} />
      {!querySessionId && <div style={{ fontSize: "18px", margin: "24px 0 12px 24px" }}>Latest sessions</div>}
      <div
        style={
          query.get("sessionid")
            ? undefined
            : {
                margin: "0 16px",
                height: "240px",
                overflow: "auto",
                padding: "4px 0",
                border: "1px solid #c4c4c4",
                borderRadius: "16px",
              }
        }
      >
        {uncoupledSessionsFiltered.length === 0 ? (
          <div className="placeholder" style={{ marginTop: "16px" }}>
            No sessions...
          </div>
        ) : (
          <>
            {uncoupledToday.length > 0 && uncoupledRest.length > 0 && <div className="list-header">Today</div>}
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content min-content",
                rowGap: "4px",
              }}
            >
              {getSessionOptions(uncoupledToday)}
            </div>
            {uncoupledToday.length > 0 && uncoupledRest.length > 0 && <div className="list-header">Other</div>}
            <div
              style={{
                display: "grid",
                gridAutoRows: "min-content",
                gridTemplateColumns: "min-content auto min-content min-content",
                rowGap: "4px",
              }}
            >
              {getSessionOptions(uncoupledRest)}
            </div>
          </>
        )}
      </div>
      {userInfo.email === "thisbecasper@gmail.com" && (
        <>
          <Header level={2} title="Driver (Admin only)" smallTopMargin={!!querySessionId} />
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <input
              className="input"
              placeholder="Other driver email..."
              value={driverOverwriteValue}
              onFocus={() => setIsInputting(true)}
              onBlur={() => setIsInputting(false)}
              onChange={(e) => setDriverOverwriteValue(e.target.value)}
            />
            <select placeholder="Existing users..." style={{ marginTop: "12px" }} onChange={(e) => setDriverOverwriteValue(e.target.value)}>
              <option value="">None</option>
              {allUsers
                .sort((a, b) => a.fullName.localeCompare(b.fullName))
                .map((user) => (
                  <option key={user.email} value={user.email}>
                    {user.fullName}
                  </option>
                ))}
            </select>
          </div>
        </>
      )}
      <Header level={2} title="Lap time" smallTopMargin={!!querySessionId} />
      <SectionContainer>
        <RadioGroup
          options={[
            { label: "Lap time", value: true },
            { label: "Sector times", value: false },
          ]}
          currentValue={lapTimeSelected}
          onClick={setLapTimeSelected}
          style={{ marginTop: "24px" }}
        />
        {lapTimeSelected ? (
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "24px", alignItems: "center" }}>
            <div style={{ fontSize: "20px" }}>Lap: </div>
            <TimeInput value={inputValue} onChange={setInputValue} onlyHudreths={dd2Session} />
          </div>
        ) : (
          <div>
            {sectors.map((s, i) => (
              <div key={i} style={{ display: "flex", justifyContent: "space-between", marginTop: "24px", alignItems: "center" }}>
                <div style={{ fontSize: "20px" }}>Sector {i + 1}: </div>
                <TimeInput value={s} onChange={(v) => updateSector(i + 1, v)} onlyHudreths={dd2Session} />
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "24px", alignItems: "center" }}>
              <div style={{ fontSize: "20px" }}>Laptime: </div>
              <div style={{ fontSize: "40px" }}>{msToLaptime(sectors.map((s) => laptimeToMs(s, dd2Session)))}</div>
            </div>
            <div style={{ margin: "24px 0", display: "flex", justifyContent: "space-around" }}>
              <Button
                text=""
                icon={<MinusOutlined />}
                danger
                onClick={() => setSectors(sectors.slice(0, -1))}
                disabled={sectors.length === 1}
              />
              <Button text="" icon={<PlusOutlined />} onClick={() => setSectors(sectors.concat(""))} />
            </div>
          </div>
        )}
      </SectionContainer>
      <Header level={2} title="Weight & Kart number" />
      <SectionContainer>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <NumberInput value={weight} onChange={setWeight} style={{ width: "68px", fontSize: "30px" }} placeholder="..." />
          <div style={{ fontSize: "30px", marginLeft: "8px" }}>kg</div>
          <div style={{ fontSize: "30px", marginLeft: "48px", marginRight: "8px" }}>No.</div>
          <NumberInput value={kartNumber} onChange={setKartNumber} style={{ width: "66px", fontSize: "30px" }} placeholder="..." />
        </div>
      </SectionContainer>
      <Header level={2} title="Notes" />
      <textarea
        placeholder="Write here..."
        rows={6}
        value={notes}
        onFocus={() => setIsInputting(true)}
        onBlur={() => setIsInputting(false)}
        onChange={(e) => setNotes(e.target.value)}
        style={{
          background: "none",
          resize: "none",
          borderRadius: "16px",
          padding: "8px 16px",
          fontSize: "20px",
          fontFamily: "f1-regular",
          width: "calc(100% - 64px)",
          outline: "none",
          borderColor: "#c4c4c4",
          margin: "0 16px",
          color: "#5d5d5d",
        }}
      />
      <Header level={2} title="Favorite" />
      <div style={{ textAlign: "center", fontSize: "32px" }}>
        {favorited ? (
          <StarFilled style={{ color: "gold" }} onClick={() => setFavorited(false)} />
        ) : (
          <StarOutlined onClick={() => setFavorited(true)} />
        )}
      </div>
      <Header level={2} title="YouTube onboards" />
      <SectionContainer>
        <div style={{ display: "grid", rowGap: 16, marginTop: 30 }}>
          {youtubeUrls.map((url, i) => (
            <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <input
                className="input input__small-input"
                placeholder="YouTube URL..."
                value={url}
                onFocus={() => setIsInputting(true)}
                onBlur={() => setIsInputting(false)}
                onChange={(e) => updateYoutubeUrls(i, e.target.value)}
              />
              <DeleteOutlined style={{ fontSize: 18 }} onClick={() => updateYoutubeUrls(i, "")} />
            </div>
          ))}
          <input
            className="input input__small-input"
            placeholder="YouTube URL..."
            value={newUrl}
            onFocus={() => setIsInputting(true)}
            onBlur={() => {
              setIsInputting(false);
              updateYoutubeUrls(youtubeUrls.length, newUrl);
              setNewUrl("");
            }}
            onChange={(e) => setNewUrl(e.target.value)}
          />
        </div>
      </SectionContainer>
      <div style={{ display: "flex", marginTop: "72px", justifyContent: "center" }}>
        <Button
          text={`${stintId ? "Update" : "Create"} stint`}
          disabled={!selectedSessionId || (lapTimeSelected ? !inputValue : !!sectors.reduce((prev, curr) => prev || !curr, false))}
          onClick={() => {
            const session = sessions.find((s) => s._id === selectedSessionId);

            if (!TRACKS.find((t) => t.id === session?.track)?.layouts.find((l) => l.id === session?.layout)?.current) {
              alert("You are not allowed to edit or create a stint belonging to inactive track layout");
              return;
            }

            setIsWorking(true);
            doFetch(
              stintId ? "PUT" : "POST",
              APIResources.Stints + (stintId ? `/${stintId}` : ""),
              () => {
                refresh();
                history.goBack();
              },
              (err) => alert(`An error occured: ${stintId ? "Updating" : "Creating"} stint: ${err}`),
              () => setIsWorking(false),
              {
                sessionId: selectedSessionId,
                time: lapTimeSelected ? [laptimeToMs(inputValue, dd2Session)] : sectors.map((s) => laptimeToMs(s, dd2Session)),
                weight: !weight ? null : Number.parseInt(weight),
                kartNumber: kartNumber ? Number.parseInt(kartNumber) : null,
                notes: notes,
                favorited: favorited,
                driverOverwrite: driverOverwriteValue,
                youtubeUrls: youtubeUrls,
              }
            );
          }}
          loading={isWorking}
        />
      </div>
    </ScrollableContainer>
  );
}
