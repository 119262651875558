import { CSSProperties } from "react";

interface RadioButtonProps {
  checked: boolean;
  style?: CSSProperties;
  onClick?(): void;
}

export function RadioButton(props: RadioButtonProps) {
  return (
    <div style={{ display: "inline-block", marginBottom: props.checked ? undefined : "-3px" }}>
      <div
        onClick={() => props.onClick && props.onClick()}
        style={{
          borderRadius: "100px",
          border: `1px solid ${props.checked ? "#1890ff" : "#c3c3c3"}`,
          height: "20px",
          width: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ...props.style,
        }}
      >
        {props.checked && <div style={{ background: "#1890ff", width: "12px", height: "12px", borderRadius: "100px" }} />}
      </div>
    </div>
  );
}
