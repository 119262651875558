import { createContext } from "react";
import { NavItem, Session, StintExtended, User, UserInfo } from "./models";

export const DataContext = createContext<{
  refresh: (onFinally?: () => void) => void;
  userInfo: UserInfo;
  sessions: Session[];
  allStints: StintExtended[];
  myStints: StintExtended[];
  uncoupledSessions: Session[];
  allUsers: User[];
  isWorking: boolean;
  setIsInputting(isInputting: boolean): void;
  getDriverName(email: string | undefined): string;
  setCurrentNavItem(navItem: NavItem): void;
}>({
  refresh: () => {},
  userInfo: { email: "", firstname: "", lastname: "", nickname: "" },
  sessions: [],
  allStints: [],
  myStints: [],
  uncoupledSessions: [],
  allUsers: [],
  isWorking: false,
  setIsInputting: () => {},
  getDriverName: () => "",
  setCurrentNavItem: () => {},
});
