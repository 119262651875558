import React from "react";
import { TimeStampAsDate } from "./timestampAsDate";
import { tagSorter } from "../lib/functions";
import { Session } from "../lib/models";

interface SessionsCardContentProps {
  trackSessions: Session[];
}

export function SessionsCardContent(props: SessionsCardContentProps) {
  return props.trackSessions.length === 0 ? (
    <div style={{ fontSize: 14, textAlign: "center", color: "#e9e9e9" }}>No sessions have been created</div>
  ) : (
    <div
      style={{ maxHeight: "185px", overflow: "hidden", marginBottom: props.trackSessions.length >= 8 ? "-16px" : undefined }}
      className={props.trackSessions.length >= 8 ? "fade" : undefined}
    >
      <div
        style={{
          display: "grid",
          gridAutoRows: "min-content",
          gridTemplateColumns: "min-content auto",
          alignItems: "center",
          columnGap: "16px",
          rowGap: "4px",
          fontSize: "15px",
        }}
      >
        {props.trackSessions.map((session) => (
          <React.Fragment key={session._id}>
            <TimeStampAsDate timestamp={session.timestamp} style={{ whiteSpace: "nowrap" }} withYear excludeTime />
            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {session.tags.sort((a, b) => tagSorter(a, b, true)).join(", ")}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
