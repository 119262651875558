import { CSSProperties } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { BLUE } from "../../lib/definitions";

interface SpinnerProps {
  style?: CSSProperties;
  iconStyle?: CSSProperties;
}

export function Spinner(props: SpinnerProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "48px 0", ...props.style }}>
      <LoadingOutlined style={{ color: BLUE, fontSize: props.style?.fontSize || "24px", ...props.iconStyle }} />
    </div>
  );
}
