import React, { useContext, useMemo } from "react";
import { DataContext } from "../lib/contexts";
import { makeLeaderboard, msToLaptime, tagSorter } from "../lib/functions";
import { StintExtended } from "../lib/models";
import { Tag } from "./general/tag";
import { TimeStampAsDate } from "./timestampAsDate";

interface StintsCardContentProps {
  trackStints: StintExtended[];
}

export function StintsCardContent(props: StintsCardContentProps) {
  const { userInfo } = useContext(DataContext);

  const myStints = useMemo(
    () =>
      props.trackStints
        .filter((s) => s.driver === userInfo.email)
        .map((s) => ({
          ...s,
          placement:
            makeLeaderboard(s.session.stints).findIndex((entry) => entry.driver === userInfo.email) + 1 + "/" + s.session.stints.length,
        })),
    [props.trackStints, userInfo.email]
  );

  return myStints.length === 0 ? (
    <div style={{ fontSize: 14, textAlign: "center" }}>You have no registered stints</div>
  ) : (
    <div
      style={{ maxHeight: "185px", overflow: "hidden", marginBottom: myStints.length >= 8 ? "-16px" : undefined }}
      className={myStints.length >= 8 ? "fade" : undefined}
    >
      <div
        style={{
          display: "grid",
          gridAutoRows: "min-content",
          gridTemplateColumns: "auto min-content min-content min-content",
          alignItems: "center",
          columnGap: "16px",
          rowGap: "4px",
          fontSize: "15px",
        }}
      >
        {myStints.map((stint) => (
          <React.Fragment key={stint._id}>
            <TimeStampAsDate timestamp={stint.session.timestamp} style={{ whiteSpace: "nowrap" }} withYear excludeTime />
            <div style={{ overflow: "hidden", whiteSpace: "nowrap", display: "flex", justifyContent: "flex-end" }}>
              {stint.session.tags
                .sort((a: string, b: string) => tagSorter(a, b))
                .map((t: any) => (
                  <Tag key={t} text={t} noText />
                ))}
            </div>
            <div style={{ textAlign: "end" }}>{stint.placement}</div>
            <div style={{ textAlign: "end" }}>{msToLaptime(stint.time)}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
