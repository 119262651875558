import { ArrowRightOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";
import { Spinner } from "./general/spinner";

interface TrackScreenCardProps {
  title: string;
  children: React.ReactNode;
  className: string;
  spin: boolean;
  onClick(): void;
  style?: CSSProperties;
}

export function TrackScreenCard(props: TrackScreenCardProps) {
  return (
    <div
      style={{
        borderRadius: "24px",
        padding: "16px 24px",
        color: "white",
        fontSize: "24px",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        cursor: "pointer",
        ...props.style,
      }}
      onClick={props.onClick}
      className={props.className}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
        <div>{props.title}</div>
        <ArrowRightOutlined />
      </div>
      {props.spin ? (
        <div>
          <Spinner iconStyle={{ fontSize: "30px", color: "white" }} />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
}
